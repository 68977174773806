import React from "react";
import { useSearchParams } from "react-router-dom";
import TimeLineDay from "./TimeLineDay";

function TimeLine(props) {
  const [params] = useSearchParams();
  const URLParams = Object.fromEntries([...params]);
  const percentSqftCompleted =
    props.schedulerContent === "Jobs"
      ? props.item.squareFeetCompleted / props.item.totalSquareFoot
      : props.item.squareFeetCompleted / props.item.squareFeetTotal;
  const numberofDaysToColor =
    props.schedulerContent === "Jobs"
      ? Math.floor(props.item.projectedDaysInJob * percentSqftCompleted)
      : Math.floor(props.item.projectedDaysInStation * percentSqftCompleted);

  function TimeLineDays() {
    let days = [];
    var dayToRepresent = new Date(URLParams.startDate);
    let startDate = new Date(props.item.startDate);
    let startColoringAfter = new Date(props.item.startDate);
    startColoringAfter.setDate(
      startColoringAfter.getDate() + numberofDaysToColor
    );
    let endDate = new Date(
      props.schedulerContent === "Jobs"
        ? props.item.projectedEndDate
        : props.item.endDate
    );
    let colorDay = " completed";
    for (let i = 0; i < props.numberOfDays; i++) {
      dayToRepresent.setDate(dayToRepresent.getDate() + 1);
      if (
        props.item.startDate !== null &&
        props.item.endDate !== null &&
        startDate <= dayToRepresent &&
        dayToRepresent <= endDate
      ) {
        if (dayToRepresent > startColoringAfter) {
          colorDay = " notCompleted";
        }
      }

      days.push(
        <TimeLineDay
          key={dayToRepresent.toLocaleDateString()}
          currentIndex={i}
          item={props.item}
          representedDay={dayToRepresent.toLocaleDateString()}
          gridSize={props.gridSize}
          colorDay={colorDay}
          schedulerContent={props.schedulerContent}
        />
      );
    }
    return days;
  }

  return <div className="timeLine flexRow">{TimeLineDays()}</div>;
}

export default TimeLine;
