import React from "react";

function GanttZoom(props) {
  function ChangeZoom(event) {
    props.ChangeGridSize(event.target.value);
  }

  return (
    <div className="ganttZoom flexColumn">
      <div>Cell Width</div>
      <div className="ganttZoomBar">
        -{" "}
        <input
          type="range"
          min="25"
          max="150"
          className="ganttZoomSlider"
          id="ganttZoomSlider"
          onMouseUp={ChangeZoom}
        />{" "}
        +
      </div>
    </div>
  );
}

export default GanttZoom;
