import React from "react";
import DailyReportLabel from "./DailyReportLabel";

const DailyReportLabelColumn = (props) => {
  return (
    <div className="dailyReportLabelColumn">
      <DailyReportLabel line1="" />
      <DailyReportLabel line1="SY Quality /" line2="Measurements" />
      <DailyReportLabel line1="A & B Binder" line2="(Gallons Used)" />
      <DailyReportLabel
        line1="Spread Rate for Binder"
        line2="(Mil Thickness)"
      />
      <DailyReportLabel line1="Aggregate Spread Rate" line2="(Mil Thickness)" />
      <DailyReportLabel line1="Aggregate" line2="(Pounds Used)" />
      <DailyReportLabel line1="Set Cure Time" />
      <DailyReportLabel line1="Binder Batch Numbers" line2="per Application" />
      <DailyReportLabel
        line1="Aggregate Batch Number"
        line2="per Application"
      />
      <DailyReportLabel line1="Application Completed" line2="to Spec (Y/N)" />
      <DailyReportLabel line1="Temperature" line2="*Surface *Low *High" />
    </div>
  );
};

export default DailyReportLabelColumn;
