import React from "react";

const EquipmentItem = (props) => {
  function itemClicked() {
    props.ClickAction(props.equipment.id);
  }

  return (
    <div
      className={
        props.equipment.down
          ? "multiAddListItem pointer down"
          : "multiAddListItem pointer"
      }
      onClick={itemClicked}
    >
      {props.equipment.name}
    </div>
  );
};

export default EquipmentItem;
