import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../constants";
import NotificationItem from "./NotificationItem";
import JobInfoModal from "../../Scheduler/Gantt/JobInfo/JobInfoModal";

function NotificationsButton(props) {
  const [showDropDown, SetShowDropDown] = useState(false);
  const [showJobPage, SetShowJobPage] = useState(false);
  const [jobData, SetJobData] = useState(false);

  function ShowJobModal(jobId) {
    let result = false;
    SetShowJobPage(true);
    axios
      .get(Constants.jobApiUrl + "GetJobDetail", {
        params: {
          code: Constants.APIKey,
          jobId: jobId,
        },
      })
      .then((res) => {
        result = true;
        SetJobData(res.data);
      })
      .catch((e) => SetShowJobPage(false));
    return result;
  }

  function ToggleDropdown() {
    SetShowDropDown(!showDropDown);
  }

  useEffect(() => {
    if (showDropDown && jobData != null) {
      props.ShowModal(
        <JobInfoModal
          job={jobData}
          ShowModal2={props.ShowModal2}
          modal2State={props.modal2State}
          dropDownData={props.dropDownData}
          user={props.user}
        />
      );
    }
  }, [jobData]);

  return (
    <>
      <div id="notificationsButton">
        <img
          className="notificationsIcon pointer"
          src={
            props.user.notifications.length > 0
              ? "/images/notificationsActive.png"
              : "/images/notificationsInactive.png"
          }
          alt="Notifications"
          onClick={ToggleDropdown}
        />
        {showDropDown ? (
          <div className="notificationsDropDown">
            <div className="notificationsItemList">
              {props.user.notifications.map((n) => (
                <NotificationItem
                  key={n.id}
                  message={n}
                  RefreshUserInfo={props.RefreshUserInfo}
                  ShowJobModal={ShowJobModal}
                />
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default NotificationsButton;
