import React from "react";

function EmployeesPending(props) {
  return (
    <>
      <div className="employeeTimeListing flexRow">
        <div className="boldText timeListingName">{props.employee.name}</div>
        <div
          className="modalButton flexRow pointer"
          onClick={() => props.ShowTimepunchesModal(props.employee)}
        >
          Time Punches
        </div>
      </div>
    </>
  );
}

export default EmployeesPending;
