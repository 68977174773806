import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../../../constants";
import { useMsal } from "@azure/msal-react";

const ManualTimeAddModal = (props) => {
  const [date, SetDate] = useState(new Date());
  const [hours, SetHours] = useState("0:00");
  const [employeeId, SetEmployeeId] = useState(0);
  const [costCodes, SetCostCodes] = useState(props.user.costCodes);
  const [costCodeId, SetCostCodeId] = useState(0);
  // const [daysOfWeek, SetDaysOfWeek] = useState([
  //   "-",
  //   "-",
  //   "-",
  //   "-",
  //   "-",
  //   "-",
  //   "-",
  // ]);
  // const [sundayHours, SetSundayHours] = useState("0:00");
  // const [mondayHours, SetMondayHours] = useState("0:00");
  // const [tuesdayHours, SetTuesdayHours] = useState("0:00");
  // const [wednesdayHours, SetWednesdayHours] = useState("0:00");
  // const [thursdayHours, SetThursdayHours] = useState("0:00");
  // const [fridayHours, SetFridayHours] = useState("0:00");
  // const [saturdayHours, SetSaturdayHours] = useState("0:00");

  const uid = useMsal().accounts[0].username;

  function AddTimepunch() {
    var formData = new FormData();
    formData.append("uid", uid);
    formData.append("jobId", props.station.jobId);
    formData.append("employeeId", props.user.employeeInfo.id);
    formData.append("costCode", costCodeId);
    formData.append("date", FormatDate(date));
    formData.append("hours", hours);
    axios({
      method: "post",
      url: Constants.jobApiUrl + "AddManagerTimePunch?code=" + Constants.APIKey,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formData,
    }).then((res) => {
      props.RefreshJobDetail();
      props.CloseSubModal();
    });
  }

  function FormatDate(date) {
    if (date.includes("-")) {
      return date;
    }
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  // function ChangeHoursSunday(event) {
  //   const regex = /^[0-9:\b]+$/;
  //   if (event.target.value === "" || regex.test(event.target.value)) {
  //     SetSundayHours(event.target.value);
  //   }
  // }
  // function ChangeHoursMonday(event) {
  //   const regex = /^[0-9:\b]+$/;
  //   if (event.target.value === "" || regex.test(event.target.value)) {
  //     SetMondayHours(event.target.value);
  //   }
  // }
  // function ChangeHoursTuesday(event) {
  //   const regex = /^[0-9:\b]+$/;
  //   if (event.target.value === "" || regex.test(event.target.value)) {
  //     SetTuesdayHours(event.target.value);
  //   }
  // }
  // function ChangeHoursWednesday(event) {
  //   const regex = /^[0-9:\b]+$/;
  //   if (event.target.value === "" || regex.test(event.target.value)) {
  //     SetWednesdayHours(event.target.value);
  //   }
  // }
  // function ChangeHoursThursday(event) {
  //   const regex = /^[0-9:\b]+$/;
  //   if (event.target.value === "" || regex.test(event.target.value)) {
  //     SetThursdayHours(event.target.value);
  //   }
  // }
  // function ChangeHoursFriday(event) {
  //   const regex = /^[0-9:\b]+$/;
  //   if (event.target.value === "" || regex.test(event.target.value)) {
  //     SetFridayHours(event.target.value);
  //   }
  // }
  // function ChangeHoursSaturday(event) {
  //   const regex = /^[0-9:\b]+$/;
  //   if (event.target.value === "" || regex.test(event.target.value)) {
  //     SetSaturdayHours(event.target.value);
  //   }
  // }

  function ChangeHours(event) {
    const regex = /^[0-9:\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      SetHours(event.target.value);
    }
  }

  function ChangeEmployee(event) {
    SetEmployeeId(event.target.value);
  }

  function ChangeDate(event) {
    SetDate(new Date(event.target.value));
  }

  function ChangeCostCode(event) {
    SetCostCodeId(event.target.value);
  }

  function SubmitClicked() {
    let confirmed = window.confirm(
      "Once submitted, this time can not be removed. Are you sure you want to add this time?"
    );
    if (confirmed) {
      AddTimepunch();
    }
  }

  // function ChangeWeek() {
  //   if (date instanceof Date) {
  //     var sunday = new Date(date);
  //     var currentDay = date.getDay();
  //     sunday.setDate(sunday.getDate() - currentDay);
  //     var days = [];
  //     for (var i = 0; i < 7; i++) {
  //       days.push(FormatShortDate(sunday.toISOString().slice(0, 10)));
  //       sunday.setDate(sunday.getDate() + 1);
  //     }
  //      SetDaysOfWeek(days);
  //   }
  // }

  // function FormatShortDate(date) {
  //   var newDate = date.slice(5, 10);
  //   if (newDate[0] === "0") {
  //     newDate = newDate.slice(1, 5);
  //   }
  //   newDate = newDate.replace("-", "/");
  //   return newDate;
  // }

  // useEffect(() => {
  //   ChangeWeek();
  // }, [date]);

  return (
    <div className="manualTimeAddModal">
      <h2>Add Time Manually</h2>
      <div className="manualTimeEntry">
        <div className="manualTimeEntryCell">
          <input
            className="manualTimeEntrySubmit"
            type="date"
            value={FormatDate(date.toISOString().slice(0, 10))}
            onChange={ChangeDate}
          />
        </div>
        <div className="employeeWeekSelectionRow">
          <select
            className="fieldInputSelect right extraWide"
            onChange={ChangeEmployee}
            value={employeeId}
          >
            <option key={"0"} value={"0"}>
              (Select Employee)
            </option>
            {props.selectValues.map((sv) => (
              <option key={sv.id} value={sv.id}>
                {sv.name}
              </option>
            ))}
          </select>
          <div className="manualTimeEntryRow">
            <div className="manualTimeEntryCell">Hours :</div>
            <div className="manualTimeEntryCell">
              <input
                className="manualTimeEntryInput"
                type="text"
                value={hours}
                onChange={ChangeHours}
              />
            </div>
          </div>
        </div>
        <div className="costCodeSelectRow">
          <select
            className="fieldInputSelect center extraWide"
            onChange={ChangeCostCode}
            value={costCodeId}
          >
            <option key={"0"} value={"0"}>
              (Select Cost Code)
            </option>
            {costCodes.map((cc) => (
              <option key={cc.id} value={cc.id}>
                {cc.name}
              </option>
            ))}
          </select>
        </div>
        {/* <div className="manualTimeEntryRow">
          <div className="manualTimeEntryColumn">
            <div className="manualTimeEntryCell boldText">{daysOfWeek[0]}</div>
            <div className="manualTimeEntryCell boldText">Sunday</div>
            <div className="manualTimeEntryContainerCell">
              <div className="manualTimeEntryCell right">Hours :</div>
              <div className="manualTimeEntryCell">
                <input
                  className="manualTimeEntryInput"
                  type="text"
                  value={hours}
                  onChange={ChangeHours}
                />
              </div> */}
        {/* </div>
          <div className="manualTimeEntryColumn">
            <div className="manualTimeEntryCell boldText">{daysOfWeek[1]}</div>
            <div className="manualTimeEntryCell boldText">Monday</div>
            <div className="manualTimeEntryContainerCell">
              <div className="manualTimeEntryCell">Hours :</div>
              <div className="manualTimeEntryCell">
                <input
                  className="manualTimeEntryInput"
                  type="text"
                  value={mondayHours}
                  onChange={ChangeHoursMonday}
                />
              </div>
            </div>
          </div>
          <div className="manualTimeEntryColumn">
            <div className="manualTimeEntryCell boldText">{daysOfWeek[2]}</div>
            <div className="manualTimeEntryCell boldText">Tuesday</div>
            <div className="manualTimeEntryContainerCell">
              <div className="manualTimeEntryCell">Hours :</div>
              <div className="manualTimeEntryCell">
                <input
                  className="manualTimeEntryInput"
                  type="text"
                  value={tuesdayHours}
                  onChange={ChangeHoursTuesday}
                />
              </div>
            </div>
          </div>
          <div className="manualTimeEntryColumn">
            <div className="manualTimeEntryCell boldText">{daysOfWeek[3]}</div>
            <div className="manualTimeEntryCell boldText">Wednesday</div>
            <div className="manualTimeEntryContainerCell">
              <div className="manualTimeEntryCell">Hours :</div>
              <div className="manualTimeEntryCell">
                <input
                  className="manualTimeEntryInput"
                  type="text"
                  value={wednesdayHours}
                  onChange={ChangeHoursWednesday}
                />
              </div>
            </div>
          </div>
          <div className="manualTimeEntryColumn">
            <div className="manualTimeEntryCell boldText">{daysOfWeek[4]}</div>
            <div className="manualTimeEntryCell boldText">Thursday</div>
            <div className="manualTimeEntryContainerCell">
              <div className="manualTimeEntryCell">Hours :</div>
              <div className="manualTimeEntryCell">
                <input
                  className="manualTimeEntryInput"
                  type="text"
                  value={thursdayHours}
                  onChange={ChangeHoursThursday}
                />
              </div>
            </div>
          </div>
          <div className="manualTimeEntryColumn">
            <div className="manualTimeEntryCell boldText">{daysOfWeek[5]}</div>
            <div className="manualTimeEntryCell boldText">Friday</div>
            <div className="manualTimeEntryContainerCell">
              <div className="manualTimeEntryCell">Hours :</div>
              <div className="manualTimeEntryCell">
                <input
                  className="manualTimeEntryInput"
                  type="text"
                  value={fridayHours}
                  onChange={ChangeHoursFriday}
                />
              </div>
            </div>
          </div>
          <div className="manualTimeEntryColumn">
            <div className="manualTimeEntryCell boldText">{daysOfWeek[6]}</div>
            <div className="manualTimeEntryCell boldText">Saturday</div>
            <div className="manualTimeEntryContainerCell">
              <div className="manualTimeEntryCell">Hours :</div>
              <div className="manualTimeEntryCell">
                <input
                  className="manualTimeEntryInput"
                  type="text"
                  value={saturdayHours}
                  onChange={ChangeHoursSaturday}
                />
              </div> */}
        {/* </div>
          </div> */}
        {/* </div> */}
      </div>
      <div className="submitButton pointer" onClick={SubmitClicked}>
        Submit
      </div>
    </div>
  );
};

export default ManualTimeAddModal;
