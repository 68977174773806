import React from "react";

function EditableValue(props) {
  function GetId() {
    if (props.value == null) {
      return "0";
    }
    if (isNaN(props.value)) {
      return props.selectValues.find((sv) => sv.name === props.value).id;
    }
    return props.value;
  }

  function FormatDate(date) {
    if (date.includes("-")) {
      return date;
    }
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function GetType() {
    switch (props.type) {
      case "date":
        return "editableDateValue flexRow fullWidth";
      case "dateCentered":
        return "editableCenterValue flexRow fullWidth";
      case "text":
        return "editableValue fullWidth";
      case "textCentered":
        return "editableCenterValue fullWidth";
      default:
        return "editableValue fullWidth";
    }
  }

  function GenerateLabel() {
    if (props.label == null) {
      return <></>;
    }
    if (props.superscript == null) {
      return (
        <div className="flexRow boldText rightAlignContent">
          {props.label + " "}
        </div>
      );
    }
    return (
      <div className="flexRow withSuperscript boldText rightAlignContent">
        <div>
          {props.label}
          <sup className="superscript">{props.superscript}</sup>
        </div>
        {props.label2}
      </div>
    );
  }

  return (
    <div
      className={GetType()}
      style={{ width: props.width, fontSize: props.fontSize }}
    >
      {GenerateLabel()}
      <div
        className={
          "flexRow highlightValue" + (props.boldValue ? " boldText" : "")
        }
      >
        {props.editable ? (
          props.selectValues != null ? (
            <select
              className="fieldInputSelect"
              onChange={props.onChange}
              value={GetId()}
            >
              <option key={"0"} value={"0"}></option>
              {props.selectValues.map((sv) => (
                <option key={sv.id} value={sv.id}>
                  {sv.name}
                </option>
              ))}
            </select>
          ) : (
            <>
              <input
                className={props.isDate ? "fieldInputDate" : "fieldInput"}
                type={props.isDate ? "date" : "Text"}
                value={props.isDate ? FormatDate(props.value) : props.value}
                onChange={props.onChange}
                style={{ width: props.inputWidth }}
              />
              {props.value2 != null ? (
                <span>
                  &nbsp;/&nbsp;
                  <input
                    className="fieldInput"
                    type="Text"
                    value={props.value2}
                    onChange={props.onChange2}
                    style={{ width: props.inputWidth }}
                  />
                </span>
              ) : (
                <></>
              )}
            </>
          )
        ) : props.value2 != null ? (
          props.value + " / " + props.value2
        ) : (
          props.value
        )}
      </div>
    </div>
  );
}

export default EditableValue;
