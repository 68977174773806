import { React } from "react";
import { useSearchParams } from "react-router-dom";

function GanttItemInfo(props) {
  const [params, SetParams] = useSearchParams();
  const URLParams = Object.fromEntries([...params]);

  function SendModal() {
    props.schedulerContent !== "Jobs"
      ? SetParams({
          ...URLParams,
          job: props.item.jobId,
          station: props.item.id,
        })
      : SetParams({ ...URLParams, job: props.item.id, station: null });
  }

  return (
    <div
      className={
        (props.item.entityId + " " + props.item.name).length > 45
          ? "ganttJobInfo flexRow pointer fontSize12"
          : "ganttJobInfo flexRow pointer"
      }
      onClick={SendModal}
    >
      {props.schedulerContent !== "Equipment"
        ? props.item.entityId + " " + props.item.name
        : props.item.name}
    </div>
  );
}

export default GanttItemInfo;
