import React from "react";

const EmployeeItem = (props) => {
  function itemClicked() {
    props.ClickAction(props.employee.id);
  }

  function GetSkillsTooltip() {
    if (props.employee.skills.length === 0) {
      return "No Certifications";
    }
    const skillsStringArray = [];
    props.employee.skills.forEach((skill) => {
      skillsStringArray.push(skill.name);
    });
    return skillsStringArray.join("<br>");
  }

  return (
    <div
      className="multiAddListItem pointer"
      onClick={itemClicked}
      data-tip={GetSkillsTooltip()}
    >
      {props.employee.name}
    </div>
  );
};

export default EmployeeItem;
