import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../../constants";
import { useMsal } from "@azure/msal-react";
import SkillItem from "./CertificationItem";
import SkillItemWithNumber from "./CertificationItemWithNumber";

const CertificationsModal = (props) => {
  const [assignedCertification, SetAssignedCertification] = useState(
    props.assignedList
  );
  const [unassignedCertification, SetUnassignedCertification] = useState([]);

  const uid = useMsal().accounts[0].username;

  function UpdateCertification() {
    var assignedIds = "";
    var assignedNumbers = "";
    for (var i = 0; i < assignedCertification.length; i++) {
      assignedIds += assignedCertification[i].id;
      assignedNumbers += assignedCertification[i].numberNeeded;
      if (i + 1 < assignedCertification.length) {
        assignedIds += ",";
        assignedNumbers += ",";
      }
    }

    var formData = new FormData();
    formData.append("uid", uid);
    formData.append("mainId", props.attachedId);
    formData.append("assignedIds", assignedIds);
    formData.append("type", props.type);
    formData.append("numbers", assignedNumbers);
    axios({
      method: "put",
      url: Constants.jobApiUrl + "AssignItems?code=" + Constants.APIKey,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formData,
    }).then((res) => {
      props.RefreshJobDetail();
      props.CloseSubModal();
    });
  }

  function AddCertification(id) {
    var referencedCertification = props.allCertifications.find(
      (c) => c.id === id
    );
    referencedCertification.numberNeeded = 1;
    var newAssignedCertificationList = assignedCertification.slice();
    newAssignedCertificationList.push(referencedCertification);
    SetAssignedCertification(
      newAssignedCertificationList.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
  }

  function UpdateCertificationNumber(id, value) {
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      var referencedCertification = assignedCertification.find(
        (c) => c.id === id
      );
      referencedCertification.numberNeeded = +value;
      var newAssignedCertificationList = assignedCertification.filter(
        (c) => c.id !== id
      );
      newAssignedCertificationList.push(referencedCertification);
      SetAssignedCertification(
        newAssignedCertificationList.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      );
    }
  }

  function RemoveCertification(id) {
    var newAssignedCertificationList = assignedCertification.filter(
      (c) => c.id !== id
    );
    SetAssignedCertification(
      newAssignedCertificationList.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
  }

  function SubmitClicked() {
    UpdateCertification();
  }

  function GenerateUnassignedCertification() {
    var newUnassignedCertificationList = props.allCertifications;
    assignedCertification.forEach((certification) => {
      newUnassignedCertificationList = newUnassignedCertificationList.filter(
        (c) => c.id !== certification.id
      );
    });
    SetUnassignedCertification(
      newUnassignedCertificationList.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
  }

  useEffect(() => {
    GenerateUnassignedCertification();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedCertification]);

  return (
    <div className="CertificationsModal">
      <h2>Set Certification Requirements</h2>
      <div className="multiAddListSection flexRow">
        <div className="multiAddListColumn">
          Assigned
          <div className="multiAddList">
            {assignedCertification[0] != null ? (
              assignedCertification.map((c) => (
                <SkillItemWithNumber
                  key={c.id}
                  skill={c}
                  ClickAction={RemoveCertification}
                  UpdateNumber={UpdateCertificationNumber}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="multiAddListColumn">
          Available
          <div className="multiAddList">
            {unassignedCertification[0] != null ? (
              unassignedCertification.map((c) => (
                <SkillItem
                  key={c.id}
                  skill={c}
                  ClickAction={AddCertification}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="submitButton pointer" onClick={SubmitClicked}>
        Submit
      </div>
    </div>
  );
};

export default CertificationsModal;
