import "../../css/modal.css";

const Modal = ({ HandleClose, show, children }) => {
  const showHideClassName = show ? "modal displayBlock" : "modal displayNone";

  return (
    <div className={showHideClassName}>
      <section className="modalMain">
        <button
          className="modalCloseButton pointer"
          type="button"
          onClick={HandleClose}
        >
          x
        </button>
        {children}
      </section>
    </div>
  );
};

export default Modal;
