import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../../constants";
import { useMsal } from "@azure/msal-react";
import EmployeeItem from "./EmployeeItem";

const EmployeeModal = (props) => {
  const [assignedEmployee, SetAssignedEmployee] = useState(props.assignedList);
  const [unassignedEmployee, SetUnassignedEmployee] = useState([]);
  const [filterByList, SetFilterByList] = useState([]);

  const uid = useMsal().accounts[0].username;

  function UpdateEmployee() {
    var assignedIds = "";
    for (var i = 0; i < assignedEmployee.length; i++) {
      assignedIds += assignedEmployee[i].id;
      if (i + 1 < assignedEmployee.length) {
        assignedIds += ",";
      }
    }

    var formData = new FormData();
    formData.append("uid", uid);
    formData.append("mainId", props.attachedId);
    formData.append("assignedIds", assignedIds);
    formData.append("type", props.type);
    axios({
      method: "put",
      url: Constants.jobApiUrl + "AssignItems?code=" + Constants.APIKey,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formData,
    }).then((res) => {
      props.RefreshJobDetail();
      props.CloseSubModal();
    });
  }

  function UpdateFilter(state, id) {
    if (!state) {
      SetFilterByList(filterByList.filter((item) => item !== id));
    } else {
      SetFilterByList([...filterByList, id]);
    }
  }

  function UpdateUnassignedEmployees() {
    var newUnassignedEmployeeList = props.allEmployees.filter((e) =>
      e.permissions.includes("Crew Member")
    );
    if (filterByList.length > 0) {
      newUnassignedEmployeeList = newUnassignedEmployeeList.filter((employee) =>
        filterByList.every((filterId) =>
          employee.skills.some((s) => s.id === filterId)
        )
      );
    }

    assignedEmployee.forEach(function (employee) {
      newUnassignedEmployeeList = newUnassignedEmployeeList.filter(
        (e) => e.id !== employee.id
      );
    });
    SetUnassignedEmployee(newUnassignedEmployeeList);
  }

  function AddEmployee(id) {
    var referencedEmployee = props.allEmployees.find((e) => e.id === id);
    var newAssignedEmployeeList = assignedEmployee.slice();
    newAssignedEmployeeList.push(referencedEmployee);
    SetAssignedEmployee(
      newAssignedEmployeeList.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
    console.log("added " + newAssignedEmployeeList.length);
  }

  function RemoveEmployee(id) {
    var newAssignedEmployeeList = assignedEmployee.filter((e) => e.id !== id);
    SetAssignedEmployee(
      newAssignedEmployeeList.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
    console.log("remove " + newAssignedEmployeeList.length);
  }

  function SubmitClicked() {
    UpdateEmployee();
  }

  function CheckFulfillment(skillId) {
    if (props.skillsNeeded != null) {
      if (props.skillsNeeded.some((skill) => skill.id === skillId)) {
        let currentNumberNeeded = props.skillsNeeded.find(
          (s) => s.id === skillId
        ).numberNeeded;
        let currentNumberAssigned = assignedEmployee.filter((e) =>
          e.skills.some((s) => s.id === skillId)
        ).length;
        if (currentNumberAssigned >= currentNumberNeeded) {
          return 2;
        }
        return 1;
      }
    }
    return 0;
  }

  function GetNumberNeededTooltip(skillId) {
    if (props.skillsNeeded != null) {
      if (props.skillsNeeded.some((skill) => skill.id === skillId)) {
        let currentNumberNeeded = props.skillsNeeded.find(
          (s) => s.id === skillId
        ).numberNeeded;
        let currentNumberAssigned = assignedEmployee.filter((e) =>
          e.skills.some((s) => s.id === skillId)
        ).length;
        return currentNumberAssigned + "/" + currentNumberNeeded;
      }
    }
    return "";
  }

  useEffect(() => {
    UpdateUnassignedEmployees();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedEmployee, filterByList]);

  return (
    <div className="EmployeeModal">
      <h2>Assign Employee</h2>
      <div className="multiAddListSection flexRow">
        <div className="multiAddListColumn">
          Assigned
          <div className="multiAddList">
            {assignedEmployee[0] != null ? (
              assignedEmployee.map((e) => (
                <EmployeeItem
                  key={e.id}
                  employee={e}
                  ClickAction={RemoveEmployee}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="employeeFilter">
          Filter Available By
          <div className="multiAddList filterByList">
            {props.filterByList.map((s) => (
              <div
                className={
                  CheckFulfillment(s.id) === 1
                    ? "filterByItem flexRow leftAlignContent neededSkill"
                    : CheckFulfillment(s.id) === 2
                    ? "filterByItem flexRow leftAlignContent fulfilledSkill"
                    : "filterByItem flexRow leftAlignContent"
                }
                data-tip={GetNumberNeededTooltip(s.id)}
              >
                <input
                  type="checkbox"
                  className="filterCheckbox"
                  onChange={(e) => UpdateFilter(e.target.checked, s.id)}
                ></input>
                {s.name}
              </div>
            ))}
          </div>
        </div>
        <div className="multiAddListColumn">
          Available
          <div className="multiAddList">
            {unassignedEmployee[0] != null ? (
              unassignedEmployee.map((e) => (
                <EmployeeItem
                  key={e.id}
                  employee={e}
                  ClickAction={AddEmployee}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="submitButton pointer" onClick={SubmitClicked}>
        Submit
      </div>
    </div>
  );
};

export default EmployeeModal;
