import React, { useEffect } from "react";
import EmployeesPending from "./EmployeesPending";
import EmployeeTimePunches from "./EmployeeTimePunches";
import "../../css/Time.css";

function TimeManagement(props) {
  function CloseTimePunches() {
    props.ShowModal(null);
  }

  function ShowTimePunchesModal(employee) {
    props.ShowModal(
      <EmployeeTimePunches
        employee={employee}
        CloseModal={CloseTimePunches}
        user={props.user}
        RefreshUserInfo={props.RefreshUserInfo}
      />
    );
  }

  useEffect(() => {
    if (props.modalState) {
      ShowTimePunchesModal();
    }
  }, [props.modalState, props.user]);

  return (
    <>
      <div className="timeManagementPageHeader flexRow">Time Actions</div>
      <div className="mainTimeManagementPage">
        <div className="timeManagementList flexColumn fullWidth">
          <div className="timeManagementHeader fullWidth">
            <h2 className="timeManagementHeaderText timeManagementName">
              Employee
            </h2>
          </div>
          <div className="timeManagementListInner flexColumn fullWidth">
            {props.user.employeesNeedingApproval != null ? (
              props.user.employeesNeedingApproval.map((e) => (
                <EmployeesPending
                  key={e.id}
                  employee={e}
                  user={props.user}
                  ShowTimepunchesModal={ShowTimePunchesModal}
                  CloseModal={props.CloseModal}
                  modalState={props.showModal}
                  RefreshUserInfo={props.RefreshUserInfo}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeManagement;
