import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Filter from "./Filter/Filter";
import Gantt from "./Gantt/Gantt";
import axios from "axios";
import "../../css/Gantt.css";
import JobInfoModal from "./Gantt/JobInfo/JobInfoModal";
import * as Constants from "../constants";

function Scheduler(props) {
  const [params, SetParams] = useSearchParams();
  const URLParams = Object.fromEntries([...params]);

  const [filteredList, SetFilteredList] = useState([]);
  const [signalRefreshList, SetSignalRefreshList] = useState("All");
  const [gridSize, SetGridSize] = useState(50);
  const [jobShownOnce, SetJobShownOnce] = useState(0);
  const [jobList, SetJobList] = useState([]);
  const [stationList, SetStationList] = useState([]);
  const [equipmentList, SetEquipmentList] = useState([]);
  const [schedulerContent, SetSchedulerContent] = useState("Stations");

  function GenerateInitialStartDate() {
    let newStartDate = new Date();
    let newEndDate = new Date();
    if (URLParams.startDate == null) {
      newStartDate.setDate(newStartDate.getDate() - 7);
    } else {
      newStartDate = new Date(URLParams.startDate);
      newStartDate = newStartDate.setDate(newStartDate.getDate() + 1);
    }
    if (URLParams.endDate == null) {
      newEndDate.setDate(newEndDate.getDate() + 60);
    } else {
      newEndDate = new Date(URLParams.endDate);
      newEndDate = newEndDate.setDate(newEndDate.getDate() + 1);
    }
    SetParams({
      ...URLParams,
      startDate: FormatDate(newStartDate),
      endDate: FormatDate(newEndDate),
    });
    SetSignalRefreshList(1);
  }

  function FormatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function ShowJobPage() {
    if (URLParams.job != null && URLParams.job !== "" && jobList != null) {
      let currentJob = jobList.find((j) => j.id.toString() === URLParams.job);
      if (currentJob != null) {
        let selectedStation =
          URLParams.station != null && URLParams.station != ""
            ? URLParams.station
            : "";
        props.ShowModal(
          <JobInfoModal
            job={currentJob}
            ShowModal2={props.ShowModal2}
            modal2State={props.modal2State}
            dropDownData={props.dropDownData}
            user={props.user}
            selectedStation={selectedStation}
          />
        );
        SetJobShownOnce(1);
      }
    }
  }

  function ChangeSchedulerContent(content) {
    SetFilteredList(content);
  }

  function GetListToFilter(content) {
    switch (content) {
      case "Jobs":
        return jobList;
      case "Stations":
        return stationList;
      case "Equipment":
        return equipmentList;
      default:
        return [];
    }
  }

  function RemoveJobFromURL() {
    if (props.modalState === false && URLParams.job != null && jobShownOnce) {
      SetParams({ ...URLParams, job: null, station: null });
    }
  }

  function RefreshList(type) {
    SetSignalRefreshList(type);
  }

  function ChangeFilterDate(sentStartDate, sentEndDate) {
    SetParams({ ...URLParams, startDate: sentStartDate, endDate: sentEndDate });
    SetSignalRefreshList(1);
  }

  function GetTimespan() {
    var startDate = new Date(URLParams.startDate);
    var endDate = new Date(URLParams.endDate);

    var timeDifference = endDate.getTime() - startDate.getTime();
    var dayDifference = timeDifference / 86400000 + 1;

    return dayDifference;
  }

  function GetJobs() {
    axios
      .get(Constants.jobApiUrl + "GetJobs", {
        params: {
          code: Constants.APIKey,
          startDate: URLParams.startDate,
          endDate: URLParams.endDate,
        },
      })
      .then((res) => {
        SetJobList(res.data);
        SetSignalRefreshList(0);
      });
  }

  function GetStationList() {
    axios({
      method: "get",
      url: Constants.jobApiUrl + "GetStations",
      params: {
        code: Constants.APIKey,
        startDate: URLParams.startDate,
        endDate: URLParams.endDate,
      },
    })
      .then((res) => {
        SetStationList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function GetEquipmentList() {
    axios({
      method: "get",
      url: Constants.jobApiUrl + "GetEquipmentWithAssignment",
      params: {
        code: Constants.APIKey,
        startDate: URLParams.startDate,
        endDate: URLParams.endDate,
      },
    })
      .then((res) => {
        SetEquipmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function CheckIfItemMatchesSearch(item) {
    let search = URLParams.search == null ? "" : URLParams.search.toLowerCase();

    let nameMatches = item.name.toLowerCase().includes(search);
    let entityIdMatches =
      item.entityId != null ? item.entityId.toString().includes(search) : false;
    let customerMatches =
      item.customer != null
        ? item.customer.toLowerCase().includes(search)
        : false;

    return nameMatches || entityIdMatches || customerMatches;
  }

  function FilterList(listToFilter) {
    if (listToFilter == null) {
      listToFilter = GetListToFilter(schedulerContent);
    }
    let statusList =
      URLParams.status == null ? [] : URLParams.status.split(",");
    if (listToFilter.length > 0) {
      let newFilteredList = listToFilter.filter((i) =>
        CheckIfItemMatchesSearch(i)
      );
      if (statusList.length > 0 && statusList[0] !== "") {
        newFilteredList = newFilteredList.filter((i) =>
          statusList.some((s) => {
            return s === i.status;
          })
        );
      }
      SetFilteredList(newFilteredList);
    }
  }

  function SearchTextChange(event) {
    SetParams({ ...URLParams, search: event.target.value });
  }

  function ChangeGridSize(newSize) {
    SetGridSize(newSize);
  }

  useEffect(() => {
    GenerateInitialStartDate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URLParams.startDate, URLParams.endDate]);

  useEffect(() => {
    switch (signalRefreshList) {
      case "Jobs":
        GetJobs();
        SetSignalRefreshList("");
        break;
      case "Stations":
        GetStationList();
        SetSignalRefreshList("");
        break;
      case "Equipment":
        GetEquipmentList();
        SetSignalRefreshList("");
        break;
      case "All":
        GetJobs();
        GetStationList();
        GetEquipmentList();
        SetSignalRefreshList("");
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRefreshList]);

  useEffect(() => {
    FilterList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobList, stationList, equipmentList, URLParams.search, URLParams.status]);

  useEffect(() => {
    ShowJobPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URLParams.job, jobList, props.dropDownData]);

  useEffect(() => {
    RemoveJobFromURL();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalState]);

  useEffect(() => {
    if (!Array.isArray(filteredList)) {
      SetSchedulerContent(filteredList);
      FilterList(GetListToFilter(filteredList));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredList]);

  useEffect(() => {
    switch (schedulerContent) {
      case "Jobs":
        GetJobs();
        SetSignalRefreshList("");
        break;
      case "Stations":
        GetStationList();
        SetSignalRefreshList("");
        break;
      case "Equipment":
        GetEquipmentList();
        SetSignalRefreshList("");
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URLParams.startDate, URLParams.endDate, schedulerContent]);

  return (
    <div className="gantt flexColumn">
      <Filter
        RefreshList={RefreshList}
        ChangeFilter={FilterList}
        ShowModal={props.ShowModal}
        SearchTextChange={SearchTextChange}
        ChangeFilterDate={ChangeFilterDate}
        ChangeGridSize={ChangeGridSize}
        gridSize={gridSize}
        dropDownData={props.dropDownData}
        ChangeSchedulerContent={ChangeSchedulerContent}
        schedulerContent={schedulerContent}
      />
      <Gantt
        ShowModal={props.ShowModal}
        list={Array.isArray(filteredList) ? filteredList : []}
        filterStartDate={URLParams.startDate}
        filterEndDate={URLParams.endDate}
        numberOfDays={GetTimespan()}
        gridSize={gridSize}
        dropDownData={props.dropDownData}
        RefreshList={RefreshList}
        CloseModal={props.CloseModal}
        schedulerContent={schedulerContent}
      />
    </div>
  );
}
export default Scheduler;
