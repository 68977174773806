import React from "react";

const SkillItemWithNumber = (props) => {
  function itemClicked() {
    props.ClickAction(props.skill.id);
  }

  function ChangeCertificationNumber(event) {
    props.UpdateNumber(props.skill.id, event.target.value);
  }

  return (
    <div className="multiAddListItemWithNumber flexRow">
      <div className="multiAddListItem pointer" onClick={itemClicked}>
        {props.skill.name}
      </div>
      <div className="multiAddListNumber">
        <input
          type="text"
          value={props.skill.numberNeeded}
          onChange={ChangeCertificationNumber}
        />
      </div>
    </div>
  );
};

export default SkillItemWithNumber;
