import React, { useEffect, useState } from "react";
import Equipment from "./Equipment";
import AddEquipmentForm from "./AddEquipment";
import axios from "axios";
import "../../css/Equipment.css";
import * as Constants from "../constants";

function EquipmentManagement(props) {
  const [equipmentList, SetEquipmentList] = useState();
  const [showAddEquipmentForm, SetShowAddEquipmentForm] = useState(false);

  function ToggleAddEquipmentForm() {
    SetShowAddEquipmentForm(!showAddEquipmentForm);
  }

  function GetEquipment() {
    axios
      .get(Constants.jobApiUrl + "GetEquipment", {
        params: {
          code: Constants.APIKey,
        },
      })
      .then((res) => {
        SetEquipmentList(res.data);
      });
  }

  useEffect(() => {
    GetEquipment();
  }, []);

  return (
    <>
      <div className="equipmentPageHeader flexRow">Equipment Management</div>
      <div className="mainEquipmentPage">
        <div className="equipmentList flexColumn fullWidth">
          <div className="equipmentHeader fullWidth">
            <h2 className="equipmentHeaderText">Name</h2>
            <h2 className="equipmentHeaderText">Status</h2>
          </div>
          <div className="equipmentListInner flexColumn fullWidth">
            {equipmentList ? (
              equipmentList.map((t) => (
                <Equipment
                  key={t.id}
                  equipment={t}
                  RefreshEquipment={GetEquipment}
                />
              ))
            ) : (
              <></>
            )}
          </div>
          {/* {showAddEquipmentForm ? (
            <AddEquipmentForm
              CloseAddForm={ToggleAddEquipmentForm}
              RefreshEquipment={GetEquipment}
            />
          ) : (
            <div
              className="addJobStationButton pointer"
              onClick={ToggleAddEquipmentForm}
            >
              +
            </div> */}
          {/* )} */}
        </div>
      </div>
    </>
  );
}

export default EquipmentManagement;
