import React from "react";

const SkillItem = (props) => {
  function itemClicked() {
    props.ClickAction(props.skill.id);
  }

  return (
    <div className="multiAddListItem pointer" onClick={itemClicked}>
      {props.skill.name}
    </div>
  );
};

export default SkillItem;
