import React, { useEffect, useState } from "react";

function TimeLineDay(props) {
  const [lineType, SetLineType] = useState(0);
  const [isToday, SetIsToday] = useState(false);
  const [colorModifier, SetColorModifier] = useState(LineColor());

  const percentSqftComplete =
    (props.sqftCompleted / props.item.totalSquareFoot) * 100;

  function TodaysSchedule(parsedRepresentedDay) {
    let todaysJobs = props.item.schedule.filter((scheduleItem) => {
      let scheduleItemStartDate = new Date(scheduleItem.startDate);
      scheduleItemStartDate.setHours(0, 0, 0, 0);
      let scheduleItemEndDate = new Date(scheduleItem.endDate);
      scheduleItemEndDate.setHours(0, 0, 0, 0);
      let startDateInPast = scheduleItemStartDate <= parsedRepresentedDay;
      let endDateInFuture = parsedRepresentedDay <= scheduleItemEndDate;
      return startDateInPast && endDateInFuture;
    });
    return todaysJobs;
  }

  function GetFullScheduleRange() {
    let startDates = props.item.schedule.map((scheduleItem) => {
      return new Date(scheduleItem.startDate);
    });
    let endDates = props.item.schedule.map((scheduleItem) => {
      return new Date(scheduleItem.endDate);
    });
    let earliestStartDate = new Date(Math.min(...startDates));
    let latestEndDate = new Date(Math.max(...endDates));
    let fullRange = {
      startDate: earliestStartDate,
      endDate: latestEndDate,
    };
    return fullRange;
  }

  function GetFirstOverLappingDay() {
    let fullScheduleRange = GetFullScheduleRange();
    let firstOverLappingDay = null;
    let todaysSchedule = TodaysSchedule(fullScheduleRange.startDate);
    if (todaysSchedule.length > 1) {
      firstOverLappingDay = fullScheduleRange.startDate;
    }
    let nextDay = new Date(fullScheduleRange.startDate);
    nextDay.setDate(nextDay.getDate() + 1);
    while (nextDay <= fullScheduleRange.endDate) {
      let todaysSchedule = TodaysSchedule(nextDay);
      if (todaysSchedule.length > 1) {
        firstOverLappingDay = nextDay;
        break;
      }
      nextDay.setDate(nextDay.getDate() + 1);
    }
    return firstOverLappingDay;
  }

  function GetLastOverLappingDay() {
    let fullScheduleRange = GetFullScheduleRange();
    let lastOverLappingDay = null;
    let todaysSchedule = TodaysSchedule(fullScheduleRange.endDate);
    if (todaysSchedule.length > 1) {
      lastOverLappingDay = fullScheduleRange.endDate;
    }
    let nextDay = new Date(fullScheduleRange.endDate);
    nextDay.setDate(nextDay.getDate() - 1);
    while (nextDay >= fullScheduleRange.startDate) {
      let todaysSchedule = TodaysSchedule(nextDay);
      if (todaysSchedule.length > 1) {
        lastOverLappingDay = nextDay;
        break;
      }
      nextDay.setDate(nextDay.getDate() - 1);
    }
    return lastOverLappingDay;
  }

  function TypeOfLine() {
    let fullScheduleRange = null;
    if (props.item.schedule != null && props.item.schedule.length !== 0) {
      fullScheduleRange = GetFullScheduleRange();
    }
    let today = new Date();
    let jobStartDate =
      fullScheduleRange != null
        ? fullScheduleRange.startDate
        : new Date(props.item.startDate);
    let jobEndDate =
      props.schedulerContent === "Jobs"
        ? new Date(props.item.projectedEndDate)
        : fullScheduleRange != null
        ? fullScheduleRange.endDate
        : new Date(props.item.endDate);
    let parsedRepresentedDay = new Date(props.representedDay);
    let drawLineBool =
      jobStartDate <= parsedRepresentedDay && parsedRepresentedDay <= jobEndDate
        ? true
        : false;
    parsedRepresentedDay.setHours(0, 0, 0, 0);
    jobStartDate.setHours(0, 0, 0, 0);
    jobEndDate.setHours(0, 0, 0, 0);
    let newLineType = 0;
    if (drawLineBool === true) {
      newLineType = 1;
      let matchesStartDate =
        parsedRepresentedDay.toLocaleDateString() ===
        jobStartDate.toLocaleDateString();
      let matchesEndDate =
        parsedRepresentedDay.toLocaleDateString() ===
        jobEndDate.toLocaleDateString();
      if (matchesStartDate === true) {
        if (matchesEndDate === true) {
          newLineType = 4;
        } else {
          newLineType = 2;
        }
      }
      if (matchesEndDate === true && matchesStartDate === false) {
        newLineType = 3;
      }
      if (props.item.schedule != null && props.item.schedule.length !== 0) {
        let todaysSchedule = TodaysSchedule(parsedRepresentedDay);
        if (todaysSchedule.length > 1) {
          newLineType = 5;
        }
        let firstOverLappingDay = GetFirstOverLappingDay();
        let lastOverLappingDay = GetLastOverLappingDay();
        if (firstOverLappingDay != null) {
          let matchesFirstOverLappingDay =
            parsedRepresentedDay.toLocaleDateString() ===
            firstOverLappingDay.toLocaleDateString();
          if (matchesFirstOverLappingDay === true) {
            newLineType = 6;
          }
        }
        if (lastOverLappingDay != null) {
          let matchesLastOverLappingDay =
            parsedRepresentedDay.toLocaleDateString() ===
            lastOverLappingDay.toLocaleDateString();
          if (matchesLastOverLappingDay === true) {
            newLineType = 7;
          }
        }
      }
    }
    let representingToday = false;
    if (
      today.toLocaleDateString() === parsedRepresentedDay.toLocaleDateString()
    ) {
      representingToday = true;
    }

    SetLineType(newLineType);
    SetIsToday(representingToday);
  }

  function LineColor() {
    let today = new Date();
    let parsedRepresentedDay = new Date(props.representedDay);
    let dayColor = "";
    let futureColor = "";
    if (
      today < parsedRepresentedDay &&
      parsedRepresentedDay.toLocaleDateString() !==
        today.toLocaleDateString() &&
      props.item.schedule == null
    ) {
      futureColor = "Future";
    }
    let todaysSchedule = [];
    if (props.item.schedule != null && props.item.schedule.length !== 0) {
      todaysSchedule = TodaysSchedule(parsedRepresentedDay);
    }
    if (todaysSchedule.length === 1) {
      dayColor = " singleScheduleItem";
    }
    if (todaysSchedule.length > 1) {
      dayColor = " multiScheduleItem";
    }

    return dayColor + futureColor;
  }

  function GenerateEquipmentToolTip() {
    if (props.item.schedule != null && props.item.schedule.length !== 0) {
      let parsedRepresentedDay = new Date(props.representedDay);
      let todaysSchedule = TodaysSchedule(parsedRepresentedDay);
      let endDate = new Date(props.item.schedule[0].endDate);
      let toolTip = "";
      if ((todaysSchedule.length > 0) & (todaysSchedule.length < 2)) {
        if (endDate < parsedRepresentedDay) {
          toolTip =
            props.item.name +
            "<br>" +
            props.item.schedule[1].jobEntityId +
            " " +
            props.item.schedule[1].stationName;
        } else {
          toolTip =
            props.item.name +
            "<br>" +
            props.item.schedule[0].jobEntityId +
            " " +
            props.item.schedule[0].stationName;
        }
      }
      if (todaysSchedule.length > 1) {
        toolTip = props.item.name;
        props.item.schedule.map((scheduleItem) => {
          toolTip +=
            "<br>" + scheduleItem.jobEntityId + " " + scheduleItem.stationName;
        });
      }
      return toolTip;
    }
  }

  useEffect(() => {
    TypeOfLine();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.schedulerContent, props.representedDay, props.item]);

  return (
    <div
      className={
        props.currentIndex % 2 === 0
          ? "timeLineDay evenDay"
          : "timeLineDay oddDay"
      }
      style={{ minWidth: props.gridSize + "px" }}
      data-delay-show="600"
      data-tip={
        lineType !== 0
          ? props.schedulerContent !== "Equipment"
            ? props.item.entityId +
              " " +
              props.item.name +
              "<br>" + // "<br>Last Daily Report<br>0/" +
              (props.schedulerContent === "Jobs"
                ? props.item.totalSquareFoot
                : props.item.squareFeetTotal) +
              " sqft" // " SqFt<br>Hours<br>Equipment Down?"
            : props.item.schedule != null
            ? // ? props.item.name +
              //   "<br>" +
              //   props.item.schedule[0].jobEntityId +
              //   " " +
              //   props.item.schedule[0].stationName
              GenerateEquipmentToolTip()
            : null
          : ""
      }
    >
      <div
        className={
          isToday === true ? "ganttShader ganttIsToday" : "ganttShader"
        }
      >
        <div
          className={
            lineType === 1
              ? "ganttLine" + props.colorDay + colorModifier
              : lineType === 2
              ? "ganttLine ganttStartLine" + props.colorDay + colorModifier
              : lineType === 3
              ? "ganttLine ganttEndLine" + props.colorDay + colorModifier
              : lineType === 4
              ? "ganttLine ganttStartEndLine" + props.colorDay + colorModifier
              : lineType === 5 || lineType === 6 || lineType === 7
              ? "ganttLine" + props.colorDay + colorModifier
              : ""
          }
          style={{
            width:
              lineType === 3 || lineType === 4
                ? props.gridSize - 2 + "px"
                : props.gridSize + "px",
          }}
          // data-tip={
          //   lineType !== 0
          //     ? props.schedulerContent === "Equipment" &&
          //       props.item.schedule != null
          //       ? // ? props.item.name +
          //         //   "<br>" +
          //         //   props.item.schedule[0].jobEntityId +
          //         //   " " +
          //         //   props.item.schedule[0].stationName
          //         GenerateEquipmentToolTip()
          //       : ""
          //     : null
          // }
        >
          {lineType === 6 ? (
            <div
              style={{
                width: "5px",
                height: "10px",
                top: "47%",
                backgroundColor: "black",
              }}
            />
          ) : null}
          {lineType === 7 ? (
            <div
              style={{
                width: "5px",
                height: "10px",
                marginLeft: props.gridSize - 5 + "px",
                top: "47%",
                backgroundColor: "black",
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default TimeLineDay;
