import React, { useState } from "react";
import StationInfo from "./StationInfo";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import * as Constants from "../../../../constants";

function JobStations(props) {
  const [expandStations, SetExpandStations] = useState(
    props.selectedStation != null &&
      props.selectedStation !== "" &&
      props.selectedStation !== "null"
      ? true
      : false
  );

  function ToggleStationsView() {
    SetExpandStations(!expandStations);
  }

  const uid = useMsal().accounts[0].username;

  function AddStation() {
    var formData = new FormData();
    formData.append("jobId", props.jobDetail.id);
    formData.append("name", "Station ");
    formData.append("latitude", "0");
    formData.append("longitude", "0");
    formData.append("squareFeetTotal", "0");
    formData.append("squareFeetCompleted", "0");
    formData.append("pavementTypeId", "0");
    formData.append("startDateTime", new Date().toISOString());
    formData.append("endDateTime", new Date().toISOString());
    formData.append("uid", uid);
    axios({
      method: "post",
      url: Constants.jobApiUrl + "AddStation?code=" + Constants.APIKey,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formData,
    }).then((res) => {
      props.RefreshDetail();
    });
  }

  function CloseSubModal() {
    props.ShowModal2(null);
  }

  return (
    <>
      <div className="jobStationHeader pointer" onClick={ToggleStationsView}>
        <h2>{expandStations ? "-" : "+"}</h2>
        <h2 className="jobStationHeaderLabel">Stations</h2>
      </div>
      {expandStations && props.jobDetail != null ? (
        <>
          {props.jobDetail.stations.map((s) => (
            <StationInfo
              key={s.id}
              station={s}
              jobDetail={props.jobDetail}
              RefreshDetail={props.RefreshDetail}
              ShowModal2={props.ShowModal2}
              modal2State={props.modal2State}
              equipmentList={props.equipmentList}
              employeeList={props.employeeList}
              skillList={props.skillList}
              CloseSubModal={CloseSubModal}
              user={props.user}
              dropDownData={props.dropDownData}
              selectedStation={props.selectedStation}
            />
          ))}
          <div className="addJobStationButton pointer" onClick={AddStation}>
            +
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default JobStations;
