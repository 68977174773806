import React, { useEffect } from "react";
import axios from "axios";
import * as Constants from "../constants";
import { useMsal } from "@azure/msal-react";

function Equipment(props) {
  const [allowEdit, SetAllowEdit] = React.useState(0);
  const [newNameValue, SetNewNameValue] = React.useState(props.equipment.name);
  const [equipmentDown, SetEquipmentDown] = React.useState(
    props.equipment.down
  );

  const uid = useMsal().accounts[0].username;

  function ToggleAllowEdit() {
    SetAllowEdit(!allowEdit);
  }

  function ToggleEquipmentStatus() {
    SetEquipmentDown(!equipmentDown);
  }

  function UpdateEquipment(type) {
    var formData = new FormData();
    formData.append("equipmentId", props.equipment.id);
    formData.append("name", newNameValue);
    formData.append("uid", uid);
    formData.append("down", equipmentDown);
    axios({
      method: "put",
      url: Constants.jobApiUrl + "UpdateEquipment?code=" + Constants.APIKey,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formData,
    }).then((res) => {
      props.RefreshEquipment();
      if (type === 1) {
        ToggleAllowEdit();
      }
    });
  }

  function RemoveEquipment() {
    let confirmed = window.confirm(
      "Are you sure that you want to delete this equipment (" +
        props.equipment.name +
        ")?"
    );
    if (confirmed) {
      var formData = new FormData();
      formData.append("equipmentId", props.equipment.id);
      formData.append("uid", uid);
      axios({
        method: "delete",
        url: Constants.jobApiUrl + "RemoveEquipment?code=" + Constants.APIKey,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: formData,
      }).then((res) => {
        props.RefreshEquipment();
      });
    }
  }

  function ChangeName(event) {
    SetNewNameValue(event.target.value);
  }

  function SubmitUpdate() {
    if (newNameValue !== "") {
      UpdateEquipment(1);
    }
  }

  function CancelClicked() {
    ToggleAllowEdit();
  }

  useEffect(() => {
    if (props.equipment.down !== equipmentDown) {
      UpdateEquipment(2);
    }
  }, [equipmentDown]);

  return (
    <div className="equipmentListing flexRow">
      <div className="boldText">{props.equipment.name}</div>
      {!equipmentDown ? (
        <div
          className="modalButton flexRow fullWidth pointer"
          onClick={ToggleEquipmentStatus}
        >
          OK
        </div>
      ) : (
        <div
          className="modalButton flexRow fullWidth pointer down"
          onClick={ToggleEquipmentStatus}
        >
          Down
        </div>
      )}
      {/* {allowEdit ? (
        <div className="modalButton flexRow pointer" onClick={SubmitUpdate}>
          Submit
        </div>
      ) : (
        <div className="modalButton flexRow pointer" onClick={ToggleAllowEdit}>
          Edit
        </div>
      )}
      {allowEdit ? (
        <div className="modalButton flexRow pointer" onClick={CancelClicked}>
          Cancel
        </div>
      ) : (
        <div className="modalButton flexRow pointer" onClick={RemoveEquipment}>
          Delete
        </div>
      )} */}
    </div>
  );
}

export default Equipment;
