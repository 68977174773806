import React from "react";
import DailyReportItem from "./DailyReportItem";

function DetailedInfo(props) {
  return (
    <>
      <h3>Daily Reports</h3>
      <div className="dailyReportsList flexColumn">
        {props.dailyReports != null
          ? props.dailyReports.map((r) => (
              <DailyReportItem
                key={r.id}
                report={r}
                ShowModal2={props.ShowModal2}
              />
            ))
          : ""}
      </div>
    </>
  );
}

export default DetailedInfo;
