import React from "react";
import axios from "axios";
import * as Constants from "../constants";
import { useMsal } from "@azure/msal-react";

function AddEquipmentForm(props) {
  const [nameValue, SetNameValue] = React.useState("");

  const uid = useMsal().accounts[0].username;

  function AddEquipment() {
    var formData = new FormData();
    formData.append("name", nameValue);
    formData.append("uid", uid);
    axios({
      method: "post",
      url: Constants.jobApiUrl + "AddEquipment?code=" + Constants.APIKey,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formData,
    }).then((res) => {
      props.RefreshEquipment();
      props.CloseAddForm();
    });
  }

  function ChangeName(event) {
    SetNameValue(event.target.value);
  }

  function SubmitClicked() {
    if (nameValue !== "") {
      AddEquipment();
    }
  }

  function CancelClicked() {
    props.CloseAddForm();
  }

  return (
    <div>
      <div>
        <label>Name</label>
        <input type="text" value={nameValue} onChange={ChangeName} />
      </div>
      <div className="addEquipmentCancel" onClick={CancelClicked}>
        Cancel
      </div>
      <div className="addEquipmentSubmit" onClick={SubmitClicked}>
        Submit
      </div>
    </div>
  );
}

export default AddEquipmentForm;
