import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import * as Constants from "../../constants";

function LoginButton(props) {
  const [showDropDown, SetShowDropDown] = useState(false);

  const { instance } = useMsal();

  function ToggleDropdown() {
    SetShowDropDown(!showDropDown);
  }

  function ManualSync() {
    Constants.ShowLoading();
    axios({
      method: "get",
      url:
        Constants.jobApiUrl +
        "FullNetsuiteSync?code=" +
        Constants.APIKey +
        "&refresh=1",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        Constants.HideLoading();
      })
      .catch(() => {
        Constants.HideLoading();
      });
  }

  function SignOutPopup() {
    const logoutRequest = {
      account: instance.getAccountByHomeId(
        instance.getAllAccounts().homeAccountId
      ),
      mainWindowRedirectUri: Constants.redirectUri,
      postLogoutRedirectUri: Constants.redirectUri,
    };
    instance.logoutPopup(logoutRequest);
  }

  return (
    <>
      <div id="loginButton">
        <div className="headerButton pointer" onClick={ToggleDropdown}>
          {useMsal().accounts[0].name}
        </div>
        {showDropDown ? (
          <div className="headerDropDown">
            <div className="headerDropDownItem pointer" onClick={ManualSync}>
              Sync
            </div>
            <div className="headerDropDownItem pointer" onClick={SignOutPopup}>
              Sign Out
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default LoginButton;
