import React from "react";

const JobFilesItem = (props) => {
  return (
    <div className="JobFile">
      <a href={props.file.URI} target="_blank" rel="noreferrer">
        <img src={props.file.URI} height={"150px"} width={"150px"} />
      </a>
    </div>
  );
};

export default JobFilesItem;
