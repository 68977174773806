import React from "react";
import { useSearchParams } from "react-router-dom";

function DateRange(props) {
  const [params] = useSearchParams();
  const URLParams = Object.fromEntries([...params]);

  function HandleChange() {
    let newStartDate = document.getElementById("ganttStartDateInput").value;
    let newEndDate = document.getElementById("ganttEndDateInput").value;
    props.ChangeFilterDate(newStartDate, newEndDate);
  }

  return (
    <div className="filterField dateRange">
      <div className="dateRangeLabel">Date Range</div>
      <div className="flexRow">
        <div className="date-range-input">
          <input
            id="ganttStartDateInput"
            type="date"
            value={URLParams.startDate == null ? "" : URLParams.startDate}
            onChange={HandleChange}
          />
        </div>
        <div>&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;</div>
        <div className="date-range-input">
          <input
            id="ganttEndDateInput"
            type="date"
            value={URLParams.endDate == null ? "" : URLParams.endDate}
            onChange={HandleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default DateRange;
