import { React, useState, useEffect } from "react";

function ContentFilter(props) {
  const contentOptions = ["Jobs", "Stations", "Equipment"];

  function ChangeSchedulerContent(e) {
    props.ChangeSchedulerContent(e.target.value);
  }

  return (
    <div className="filterField">
      <div className="contentFilterLabel">Content</div>
      <select
        className="contentFilterSelect"
        value={props.schedulerContent}
        onChange={ChangeSchedulerContent}
      >
        {contentOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ContentFilter;
