import React, { useEffect } from "react";
import axios from "axios";
import * as Constants from "../../../../constants";
import { useMsal } from "@azure/msal-react";
import EmployeeModal from "../../EmployeeInfo/EmployeeModal";
import EquipmentModal from "../../EquipmentInfo/EquipmentModal";
import EditableValue from "../../../../EditableValue/EditableValue";
import ProgressGraphic from "../BasicJobInfo/ProgressGraphic";
import ManualTimeAddModal from "./ManualTimeAddModal";
import ManualSquareFootAddModal from "./ManualSquareFootAddModal";

function StationInfo(props) {
  const [allowEdit, SetAllowEdit] = React.useState(0);
  const [newNameValue, SetNewNameValue] = React.useState(props.station.name);
  const [newLatitudeValue, SetLatitudeValue] = React.useState(
    props.station.latitude
  );
  const [newLongitudeValue, SetLongitudeValue] = React.useState(
    props.station.longitude
  );
  const [newStartDateValue, SetStartDateValue] = React.useState(
    props.station.startDate
  );
  const [newEndDateValue, SetEndDateValue] = React.useState(
    props.station.endDate
  );
  const [newSquareFeetTotalValue, SetNewSquareFeetTotalValue] = React.useState(
    props.station.squareFeetTotal
  );
  const [newSquareFeetCompletedValue, SetNewSquareFeetCompletedValue] =
    React.useState(props.station.squareFeetCompleted);
  const [newPavementTypeValue, SetPavementTypeValue] = React.useState(
    props.station.pavementType
  );
  const [newEstimatedTimeValue, SetEstimatedTimeValue] = React.useState(
    props.station.estimatedTime
  );
  const borderClass =
    props.station.id == props.selectedStation ? " selectedStation" : "";

  const uid = useMsal().accounts[0].username;

  function ToggleAllowEdit() {
    SetAllowEdit(!allowEdit);
  }

  function UpdateStation() {
    var formData = new FormData();
    formData.append("stationId", props.station.id);
    formData.append("jobId", props.jobDetail.id);
    formData.append("name", newNameValue);
    formData.append("latitude", newLatitudeValue);
    formData.append("longitude", newLongitudeValue);
    formData.append("squareFeetTotal", newSquareFeetTotalValue);
    formData.append("squareFeetCompleted", newSquareFeetCompletedValue);
    formData.append("pavementType", newPavementTypeValue);
    formData.append("estimatedTime", newEstimatedTimeValue);
    formData.append(
      "oldSquareFeetCompleted",
      props.station.squareFeetCompleted
    );
    formData.append("startDateTime", Constants.FormatDate(newStartDateValue));
    formData.append("endDateTime", Constants.FormatDate(newEndDateValue));
    formData.append("employeeId", props.user.employeeInfo.id);
    formData.append("uid", uid);
    axios({
      method: "put",
      url: Constants.jobApiUrl + "UpdateStation?code=" + Constants.APIKey,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formData,
    }).then((res) => {
      props.RefreshDetail();
      ToggleAllowEdit();
    });
  }

  function RemoveStation() {
    let confirmed = window.confirm(
      "Are you sure that you want to delete this station (" +
        props.station.name +
        ")?"
    );
    if (confirmed) {
      var formData = new FormData();
      formData.append("stationId", props.station.id);
      formData.append("uid", uid);
      axios({
        method: "delete",
        url: Constants.jobApiUrl + "RemoveStation?code=" + Constants.APIKey,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: formData,
      }).then((res) => {
        props.RefreshDetail();
      });
    }
  }

  function ChangeName(event) {
    SetNewNameValue(event.target.value);
  }

  function ChangeLatitude(event) {
    const regex = /^[0-9+-.\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      SetLatitudeValue(event.target.value);
    }
  }

  function ChangeLongitude(event) {
    const regex = /^[0-9+-.\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      SetLongitudeValue(event.target.value);
    }
  }

  function ChangeStartDate(event) {
    SetStartDateValue(event.target.value);
  }

  function ChangeEndDate(event) {
    SetEndDateValue(event.target.value);
  }

  function ChangeSquareFeetTotal(event) {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      SetNewSquareFeetTotalValue(+event.target.value);
    }
  }

  function ChangeSquareFeetCompleted(event) {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      SetNewSquareFeetCompletedValue(+event.target.value);
    }
  }

  function ChangePavementType(event) {
    SetPavementTypeValue(event.target.value);
  }

  function ChangeEstimatedTime(event) {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      SetEstimatedTimeValue(+event.target.value);
    }
  }

  function SubmitUpdate() {
    if (
      newNameValue !== "" &&
      newLatitudeValue !== "" &&
      newLongitudeValue !== ""
    ) {
      UpdateStation();
    }
  }

  function CancelClicked() {
    ToggleAllowEdit();
    SetNewNameValue(props.station.name);
    SetLatitudeValue(props.station.latitude);
    SetLongitudeValue(props.station.longitude);
    SetNewSquareFeetTotalValue(props.station.squareFeetTotal);
    SetNewSquareFeetCompletedValue(props.station.squareFeetCompleted);
    SetPavementTypeValue(props.station.pavementType);
    SetStartDateValue(props.station.startDate);
    SetEndDateValue(props.station.endDate);
  }

  function ShowEmployeeModal() {
    props.ShowModal2(
      <EmployeeModal
        allEmployees={props.employeeList}
        attachedId={props.station.id}
        assignedList={props.station.employees}
        type="employeeToStation"
        RefreshJobDetail={props.RefreshDetail}
        CloseSubModal={props.CloseSubModal}
        filterByList={props.skillList}
      />
    );
  }

  function ShowEquipmentModal() {
    props.ShowModal2(
      <EquipmentModal
        allEquipment={props.equipmentList}
        attachedId={props.station.id}
        assignedList={props.station.equipment}
        type="equipmentToStation"
        CloseSubModal={props.CloseSubModal}
        RefreshJobDetail={props.RefreshDetail}
        dropDownData={props.dropDownData}
      />
    );
  }

  // function ShowManualTimeEntryModal() {
  //   props.ShowModal2(
  //     <ManualTimeAddModal
  //       station={props.station}
  //       user={props.user}
  //       CloseSubModal={props.CloseSubModal}
  //       RefreshJobDetail={props.RefreshDetail}
  //       dropDownData={props.dropDownData}
  //       selectValues={props.dropDownData.employees}
  //     />
  //   );
  // }

  function ShowManualSquareFeetEntryModal() {
    props.ShowModal2(
      <ManualSquareFootAddModal
        station={props.station}
        user={props.user}
        CloseSubModal={props.CloseSubModal}
        RefreshJobDetail={props.RefreshDetail}
      />
    );
  }

  function PavementType() {
    let paymentType = props.dropDownData.pavementTypes.find(
      (pt) => pt.id.toString() === newPavementTypeValue
    );
    if (paymentType != null) {
      return paymentType.name;
    }
    console.log(newPavementTypeValue);
    return "";
  }

  useEffect(() => {
    SetNewNameValue(props.station.name);
    SetLatitudeValue(props.station.latitude);
    SetLongitudeValue(props.station.longitude);
    SetNewSquareFeetTotalValue(props.station.squareFeetTotal);
    SetNewSquareFeetCompletedValue(props.station.squareFeetCompleted);
    SetPavementTypeValue(props.station.pavementType);
    SetEstimatedTimeValue(props.station.estimatedTime);
    SetStartDateValue(props.station.startDate);
    SetEndDateValue(props.station.endDate);
  }, [props.station]);

  return (
    <div className={"stationCard flexColumn" + borderClass}>
      <div className="singleJobStationHeader fullWidth">
        <div className="flexRow">
          <EditableValue
            value={newNameValue}
            onChange={ChangeName}
            editable={allowEdit}
            fontSize="25px"
            boldValue={true}
            type="textCentered"
          />
        </div>
        <div className="flexColumn">
          <div className="boldText fullWidth">Pavement Type</div>
          <div className="flexRow">
            <EditableValue
              value={PavementType()}
              onChange={ChangePavementType}
              editable={allowEdit}
              selectValues={props.dropDownData.pavementTypes}
              type="textCentered"
            />
          </div>
        </div>
        {allowEdit ? (
          <div className="modalButton flexRow pointer" onClick={SubmitUpdate}>
            Submit
          </div>
        ) : (
          <div
            className="modalButton flexRow pointer"
            onClick={ToggleAllowEdit}
          >
            Edit
          </div>
        )}
        {allowEdit ? (
          <div className="modalButton flexRow pointer" onClick={CancelClicked}>
            Cancel
          </div>
        ) : (
          <div className="modalButton flexRow pointer" onClick={RemoveStation}>
            Delete
          </div>
        )}
      </div>
      <div className="flexRow fullWidth spaceBetween">
        <div className="stationInfoColumn flexColumn">
          <EditableValue
            label="Start Date: "
            value={newStartDateValue}
            onChange={ChangeStartDate}
            editable={allowEdit}
            isDate={true}
            type="date"
          />
          <EditableValue
            label="End Date: "
            value={newEndDateValue}
            onChange={ChangeEndDate}
            editable={allowEdit}
            isDate={true}
            type="date"
          />
          <div className="basicJobInfoGridCell flexColumn">
            <div
              onClick={ShowEmployeeModal}
              className="modalButton flexRow pointer"
            >
              Assign Employees
            </div>
          </div>
        </div>
        <div className="stationInfoColumn flexColumn">
          <div
            className={allowEdit ? "fullWidth" : "fullWidth pointer highlight"}
            onClick={allowEdit ? null : ShowManualSquareFeetEntryModal}
          >
            <EditableValue
              label="Ft"
              superscript="2"
              label2="Completed:"
              value={newSquareFeetCompletedValue}
              onChange={ChangeSquareFeetTotal}
              // editable={allowEdit}
            />
          </div>
          <div
            className="basicJobInfoGridCell flexColumn"
            // onClick={allowEdit ? null : ShowManualTimeEntryModal}
          >
            <EditableValue
              label="Ft"
              superscript="2"
              label2="Total:"
              value={newSquareFeetTotalValue}
              onChange={ChangeSquareFeetTotal}
              editable={allowEdit}
            />
            {/* <EditableValue
            label="Estimated Time: "
            value={newEstimatedTimeValue}
            onChange={ChangeEstimatedTime}
            editable={allowEdit}
          /> */}
          </div>
          <div className="basicJobInfoGridCell flexColumn">
            <div
              onClick={ShowEquipmentModal}
              className="modalButton flexRow pointer"
            >
              Assign Equipment
            </div>
          </div>
        </div>
        <div className="stationInfoCircleColumn flexRow spaceAround">
          <ProgressGraphic
            label="Square Foot Completed"
            value={newSquareFeetCompletedValue}
            base={newSquareFeetTotalValue}
          />
          {/* <ProgressGraphic
            label="Total Time"
            value={props.station.approvedHours}
            base={props.station.estimatedTime}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default StationInfo;
