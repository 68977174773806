import React from "react";

const DailyReportData = (props) => {
  return (
    <div className="dailyReportData flexColumn">
      <div>{props.line1}</div>
      {props.line2 !== "" ? <div>{props.line2}</div> : ""}
    </div>
  );
};

export default DailyReportData;
