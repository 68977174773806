import React from "react";

function HeaderLogo() {
  return (
    <a href="/">
      <img
        src="/images/SKIDABRADER_LOGO%2520HORIZONTAL_edited.webp"
        alt="Skidabrader Logo"
      />
    </a>
  );
}

export default HeaderLogo;
