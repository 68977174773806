import React from "react";
import DailyReportLabel from "./DailyReportLabel";
import DailyReportData from "./DailyReportData";

const DailyReportDataColumn = (props) => {
  const columnLabel2 = "Application # " + props.application.applicationNumber;
  const measurements1 =
    "(" +
    props.application.length.toFixed(2) +
    " ft x " +
    props.application.width.toFixed(2) +
    " ft) / 9";
  const measurements2Calculated =
    (props.application.length * props.application.width) / 9;
  const measurements2 = measurements2Calculated.toFixed(2) + " Yd²";
  const binderAUsed = "A - " + props.application.binderAUsed.toFixed(2);
  const binderBUsed = "B - " + props.application.binderBUsed.toFixed(2);
  const setCureTime = props.application.cureTime + " minutes";
  const binderBatchNumberA = "A - " + props.application.binderBatchNumberA;
  const binderBatchNumberB = "B - " + props.application.binderBatchNumberB;
  const completedToSpec =
    props.application.completedToSpec === "true" ? "Y" : "N";
  const temperature =
    props.application.temperatureSurface.toFixed(2) +
    "° - " +
    props.application.temperatureLow.toFixed(2) +
    "° - " +
    props.application.temperatureHigh.toFixed(2) +
    "°";

  return (
    <div className="dailyReportDataColumn">
      <DailyReportLabel line1={props.application.type} line2={columnLabel2} />
      <DailyReportData line1={measurements1} line2={measurements2} />
      <DailyReportData line1={binderAUsed} line2={binderBUsed} />
      <DailyReportData
        line1={props.application.binderSpreadRate.toFixed(2)}
        line2=""
      />
      <DailyReportData
        line1={props.application.aggregateSpreadRate.toFixed(2)}
        line2=""
      />
      <DailyReportData
        line1={props.application.aggregate.toFixed(2)}
        line2=""
      />
      <DailyReportData line1={setCureTime} line2="" />
      <DailyReportData line1={binderBatchNumberA} line2={binderBatchNumberB} />
      <DailyReportData
        line1={props.application.aggregateBatchNumber}
        line2=""
      />
      <DailyReportData line1={completedToSpec} line2="" />
      <DailyReportData line1={temperature} line2="" />
    </div>
  );
};

export default DailyReportDataColumn;
