import React from "react";
import DateRangeFilter from "./DateRangeFilter";
import SearchFilter from "./SearchFilter";
import GanttZoom from "./GanttZoom";
import StatusFilter from "./StatusFilter";
import ContentFilter from "./ContentFilter";

function Filter(props) {
  return (
    <div className="ganttFilter flexRow">
      <ContentFilter
        schedulerContent={props.schedulerContent}
        ChangeSchedulerContent={props.ChangeSchedulerContent}
      />
      <GanttZoom
        ChangeGridSize={props.ChangeGridSize}
        gridSize={props.gridSize}
      />
      <SearchFilter
        ChangeFilter={props.ChangeFilter}
        SearchTextChange={props.SearchTextChange}
      />
      <DateRangeFilter ChangeFilterDate={props.ChangeFilterDate} />

      <StatusFilter
        dropDownData={props.dropDownData}
        ChangeFilterStatus={props.ChangeFilterStatus}
      />
    </div>
  );
}

export default Filter;
