import React from "react";

const DailyReportLabel = (props) => {
  return (
    <div className="dailyReportLabel flexColumn">
      <div>{props.line1}</div>
      {props.line2 !== "" ? <div>{props.line2}</div> : ""}
    </div>
  );
};

export default DailyReportLabel;
