import React from "react";
import DailyReportDataColumn from "./DailyReportDataColumn";
import DailyReportLabelColumn from "./DailyReportLabelColumn";

const DailyReportModal = (props) => {
  return (
    <div className="dailyReportModal flexColumn">
      <div className="dailyReportHeader flexRow">
        <h2 className="dailyReportHeaderLabel">Daily Report</h2>
        <div className="dailyReportHeaderData flexRow">
          <div className="dailyReportHeaderLabel">Date:</div>
          <div className="dailyReportHeaderValue">
            {props.report.reportDate}
          </div>
        </div>
        <div className="dailyReportHeaderData flexRow">
          <div className="dailyReportHeaderLabel">Submitted by:</div>
          <div className="dailyReportHeaderValue">
            {props.report.supervisor}
          </div>
        </div>
      </div>
      <div className="dailyReportTable flexRow">
        <DailyReportLabelColumn />
        {props.report.applications.map((a) => (
          <DailyReportDataColumn
            key={a.type + a.applicationNumber}
            application={a}
          />
        ))}
      </div>
      <div className="dailyReportFooter flexRow">
        <div className="dailyReportFooterLabel flexRow">Comments:</div>
        <div className="dailyReportFooterValue flexRow">
          {props.report.comments}
        </div>
      </div>
    </div>
  );
};

export default DailyReportModal;
