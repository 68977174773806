import React from "react";
import DatesHeader from "./DatesHeader";
import TimeLine from "./TimeLine";
import GanttItemInfo from "./GanttItemInfo";
import AddJob from "./AddJob/AddJobModal";

function Gantt(props) {
  function HandleScroll(event) {
    document.getElementById("ganttJobList").scrollTop = event.target.scrollTop;
    document.getElementById("ganttDatesHeader").scrollLeft =
      event.target.scrollLeft;
  }

  // function ShowAddJobForm() {
  //   props.ShowModal(
  //     <AddJob
  //       dropDownData={props.dropDownData}
  //       RefreshList={props.RefreshList}
  //       CloseModal={props.CloseModal}
  //     />
  //   );
  // }

  return (
    <>
      <div className="ganttMain flexRow">
        <div className="ganttJobInfoColumn flexColumn">
          <div className="ganttJobHeader flexRow">
            {/* <div className="addJobButton pointer" onClick={ShowAddJobForm}>
              +Add New Job
            </div> */}
          </div>
          {props.list != null ? (
            <div className="ganttJobList" id="ganttJobList">
              {props.list.map((item) => (
                <GanttItemInfo
                  key={item.id}
                  item={item}
                  ShowModal={props.ShowModal}
                  gridSize={props.gridSize}
                  schedulerContent={props.schedulerContent}
                />
              ))}
              <div className="ganttJobListSpacer" />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="datesColumn">
          <div className="ganttDatesHeader flexRow" id="ganttDatesHeader">
            <DatesHeader
              filterStartDate={props.filterStartDate}
              filterEndDate={props.filterEndDate}
              numberOfDays={props.numberOfDays}
              gridSize={props.gridSize}
            />
            <div className="ganttDatesHeaderSpacer" />
          </div>
          {props.list != null ? (
            <div className="ganttTimelineData" onScroll={HandleScroll}>
              {props.list.map((item) => (
                <TimeLine
                  key={item.id}
                  item={item}
                  filterStartDate={props.filterStartDate}
                  filterEndDate={props.filterEndDate}
                  numberOfDays={props.numberOfDays}
                  gridSize={props.gridSize}
                  schedulerContent={props.schedulerContent}
                />
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Gantt;
