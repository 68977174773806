import * as Constants from "./Components/constants";

export const msalConfig = {
  auth: {
    clientId: "eae0fe51-1feb-47f8-a091-f38aa2733a9b",
    //authority: "https://login.microsoftonline.com/58b25321-3045-4059-94ca-94f05e33e2f1", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    authority: "https://login.microsoftonline.com/common",
    redirectUri: Constants.redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://login.microsoftonline.com/v1.0/me",
};
