import React from "react";
import { useSearchParams } from "react-router-dom";

function SearchFilter(props) {
  const [params] = useSearchParams();
  const URLParams = Object.fromEntries([...params]);

  return (
    <div className="filterField job-number-filter">
      <div className="job-number-filter-label">Search</div>
      <div className="job-number-filter-input">
        <input
          type="text"
          value={URLParams.search == null ? "" : URLParams.search}
          onChange={props.SearchTextChange}
        />
      </div>
    </div>
  );
}

export default SearchFilter;
