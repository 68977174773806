import React, { useEffect, useState } from "react";
import EmployeeModal from "../../EmployeeInfo/EmployeeModal";
import EquipmentModal from "../../EquipmentInfo/EquipmentModal";
import CertificationsModal from "../../CertificationsManager/CertificationsModal";
import JobFilesModal from "../../JobFiles/JobFilesModal";
import ProgressGraphic from "./ProgressGraphic";
import EditableValue from "../../../../EditableValue/EditableValue";
import ManualTimeAddModal from "../JobStations/ManualTimeAddModal";
import axios from "axios";
import * as Constants from "../../../../constants";
import { useMsal } from "@azure/msal-react";

function BasicJobInfo(props) {
  const [basicInfo, SetBasicInfo] = useState(InitializeBasicJobInfo());
  const [editable, SetEditable] = useState(false);

  const uid = useMsal().accounts[0].username;

  function FormatDate(date) {
    if (date.includes("-")) {
      return date;
    }
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function InitializeBasicJobInfo() {
    if (props.jobDetail != null) {
      let newBasicJobInfo = {
        customer: props.jobDetail.customer,
        startDate: props.jobDetail.startDate,
        endDate: props.jobDetail.projectedEndDate,
        scheduledEndDate: props.jobDetail.scheduledEndDate,
        projectContact: props.jobDetail.projectContact,
        numberOfStations: props.jobDetail.numberOfStations,
        totalSquareFoot: props.jobDetail.totalSquareFoot,
        status: props.jobDetail.status,
        projectManager: props.jobDetail.projectManager,
        actualTime: props.jobDetail.actualTime,
        estimatedTime: props.jobDetail.estimatedTime,
        approvedTime: props.jobDetail.approvedHours,
        unapprovedTime: props.jobDetail.unapprovedHours,
      };
      return newBasicJobInfo;
    }
    console.log("Job detail is null");
    return null;
  }

  function ToggleAllowEdit() {
    let newEditable = !editable;
    props.ChangeEditable(newEditable);
    SetEditable(newEditable);
  }

  function UpdateJob() {
    Constants.ShowLoading();
    var formData = new FormData();
    formData.append("uid", uid);
    formData.append("id", props.job.id);
    formData.append("name", props.newName);
    formData.append("startDate", FormatDate(basicInfo.startDate));
    formData.append("endDate", FormatDate(basicInfo.endDate));
    formData.append("sqFoot", basicInfo.totalSquareFoot);
    formData.append("projectManagerId", basicInfo.projectManager);
    formData.append("projectContactId", basicInfo.projectContact);
    formData.append("status", basicInfo.status);
    formData.append("estimatedTime", basicInfo.estimatedTime);
    axios({
      method: "put",
      url: Constants.jobApiUrl + "UpdateJobInfo?code=" + Constants.APIKey,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then((res) => {
        props.RefreshJobDetail();
        props.ChangeEditable(false);
        SetEditable(false);
        Constants.HideLoading();
      })
      .catch((err) => {
        Constants.HideLoading();
      });
  }

  // function ChangeCustomer(event){
  //   SetBasicInfo({ ...basicInfo, customer: event.target.value});
  // }

  function ChangeStartDate(event) {
    SetBasicInfo({ ...basicInfo, startDate: event.target.value });
  }

  function ChangeEndDate(event) {
    SetBasicInfo({ ...basicInfo, endDate: event.target.value });
  }

  function ChangeProjectContact(event) {
    SetBasicInfo({ ...basicInfo, projectContact: event.target.value });
  }

  function ChangeTotalSquareFoot(event) {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      SetBasicInfo({ ...basicInfo, totalSquareFoot: event.target.value });
    }
  }

  function ChangeEstimatedTime(event) {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      SetBasicInfo({ ...basicInfo, estimatedTime: event.target.value });
    }
  }

  function ChangeStatus(event) {
    SetBasicInfo({ ...basicInfo, status: event.target.value });
  }

  function ChangeProjectManager(event) {
    console.log(event.target.value);
    SetBasicInfo({ ...basicInfo, projectManager: event.target.value });
  }

  function ShowEmployeeModal() {
    props.ShowModal2(
      <EmployeeModal
        allEmployees={props.employeeList}
        attachedId={props.jobDetail.id}
        assignedList={props.jobDetail.employees}
        type="employeeToJob"
        RefreshJobDetail={props.RefreshJobDetail}
        CloseSubModal={CloseSubModal}
        filterByList={props.skillList}
        skillsNeeded={props.jobDetail.skillsNeeded}
      />
    );
  }

  function ShowEquipmentModal() {
    props.ShowModal2(
      <EquipmentModal
        allEquipment={props.equipmentList}
        attachedId={props.jobDetail.id}
        assignedList={props.jobDetail.equipment}
        type="equipmentToJob"
        RefreshJobDetail={props.RefreshJobDetail}
        CloseSubModal={CloseSubModal}
      />
    );
  }

  function ShowCertificationsModal() {
    props.ShowModal2(
      <CertificationsModal
        allCertifications={props.skillList}
        attachedId={props.jobDetail.id}
        assignedList={props.jobDetail.skillsNeeded}
        type="skillToJob"
        RefreshJobDetail={props.RefreshJobDetail}
        CloseSubModal={CloseSubModal}
      />
    );
  }

  function ShowJobFilesModal() {
    props.ShowModal2(
      <JobFilesModal
        jobFiles={props.jobFiles}
        attachedId={props.jobDetail.id}
        CloseSubModal={CloseSubModal}
      />
    );
  }

  function ShowManualTimeEntryModal() {
    props.ShowModal2(
      <ManualTimeAddModal
        station={props.station}
        user={props.user}
        CloseSubModal={CloseSubModal}
        RefreshJobDetail={props.RefreshDetail}
        dropDownData={props.dropDownData}
        selectValues={props.dropDownData.employees}
      />
    );
  }

  function CancelClicked() {
    SetBasicInfo(InitializeBasicJobInfo());
    props.ChangeEditable(false);
    SetEditable(false);
  }

  function CloseSubModal() {
    props.ShowModal2(null);
  }

  const completedSquareFoot =
    props.jobDetail != null
      ? props.jobDetail.stations.reduce((accumulator, object) => {
          return accumulator + object.squareFeetCompleted;
        }, 0)
      : 0;

  function DownEquipment() {
    if (
      props.jobDetail.equipment != null &&
      props.jobDetail.equipment[0] != null
    ) {
      let downedEquipment = props.jobDetail.equipment.filter(
        (equipment) => equipment.down === true
      );
      if (downedEquipment.length > 0) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    SetBasicInfo(InitializeBasicJobInfo());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.jobDetail]);

  return (
    <div className="flexColumn fullWidth">
      <div className="basicJobInfoModalHeader bottomPadding fullWidth">
        <div className="flexRow boldText">{props.job.customer}</div>
        <div>
          {props.dropDownData != null ? (
            <EditableValue
              value={
                props.dropDownData.statuses.find(
                  (s) => s.id.toString() === basicInfo.status
                ).name
              }
              editable={editable}
              selectValues={props.dropDownData.statuses}
              onChange={ChangeStatus}
              type="textCentered"
              boldValue={true}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="flexRow rightAlignContent">
          {editable ? (
            <div className="modalButton2 flexRow pointer" onClick={UpdateJob}>
              Submit
            </div>
          ) : (
            <div
              className="modalButton2 flexRow pointer"
              onClick={ToggleAllowEdit}
            >
              Edit
            </div>
          )}
          {editable ? (
            <div
              className="modalButton2 flexRow pointer"
              onClick={CancelClicked}
            >
              Cancel
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {basicInfo.startDate != null ? (
        <div className="fullWidth flexRow">
          <div className="basicJobInfo">
            <div className="basicJobInfoGridCell flexRow leftAlignContent"></div>
            <div className="basicJobInfoGridCell flexRow spaceAround">
              <div className="fullHeight fullWidth">
                <div className="boldText fullWidth">Start Date</div>
                <div>
                  <EditableValue
                    value={basicInfo.startDate}
                    editable={editable}
                    onChange={ChangeStartDate}
                    isDate={true}
                    type="dateCentered"
                  />
                </div>
              </div>
              <div className="fullHeight fullWidth">
                <div className="boldText fullWidth">End Date</div>
                <div>
                  <EditableValue
                    value={basicInfo.endDate}
                    editable={editable}
                    onChange={ChangeEndDate}
                    isDate={true}
                    type="dateCentered"
                  />
                </div>
              </div>
            </div>
            <div className="basicJobInfoGridCell flexRow leftAlignContent">
              {props.employeeList != null &&
              props.employeeList.find(
                (c) => c.id.toString() === basicInfo.projectManager
              ) != null ? (
                <EditableValue
                  label="Project Manager:"
                  value={
                    props.employeeList.find(
                      (c) => c.id.toString() === basicInfo.projectManager
                    ).name
                  }
                  editable={editable}
                  selectValues={props.employeeList.filter(
                    (e) => e.regionId != 0
                  )}
                  onChange={ChangeProjectManager}
                />
              ) : (
                <></>
              )}
            </div>
            {/* <div
              className="basicJobInfoGridCell flexColumn pointer"
              onClick={editable ? null : ShowManualTimeEntryModal}
            > */}
            <div className="basicJobInfoGridCell flexRow leftAlignContent">
              {props.dropDownData != null ? (
                <EditableValue
                  label="Ft"
                  superscript="2"
                  label2="Total:"
                  value={basicInfo.totalSquareFoot}
                  editable={editable}
                  onChange={ChangeTotalSquareFoot}
                />
              ) : (
                <></>
              )}
            </div>
            {/* </div> */}
            <div className="basicJobInfoGridCell flexRow leftAlignContent">
              {props.dropDownData != null &&
              props.dropDownData.contacts.find(
                (c) => c.id.toString() === basicInfo.projectContact
              ) != null ? (
                <EditableValue
                  label="Customer Contact:"
                  value={
                    props.dropDownData.contacts.find(
                      (c) => c.id.toString() === basicInfo.projectContact
                    ).name
                  }
                  editable={editable}
                  selectValues={props.dropDownData.contacts}
                  onChange={ChangeProjectContact}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="basicJobInfoGridCell flexRow leftAlignContent">
              {props.dropDownData != null ? (
                <EditableValue
                  label="Estimated Time:"
                  value={basicInfo.estimatedTime}
                  editable={editable}
                  onChange={ChangeEstimatedTime}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="basicJobInfoGridCell flexColumn">
              <div
                onClick={ShowCertificationsModal}
                className="modalButton flexRow pointer"
              >
                Certification Requirements
              </div>
            </div>
            {/* <div className="basicJobInfoGridCell flexColumn">
              <div
                onClick={ShowEmployeeModal}
                className="modalButton flexRow pointer"
              >
                Assign Employees
              </div>
            </div> */}
            <div className="basicJobInfoGridCell flexColumn">
              <div
                className="modalButton flexRow pointer"
                onClick={ShowJobFilesModal}
              >
                View Job Photos / Files
              </div>
            </div>
            {/* <div className="basicJobInfoGridCell flexColumn">
              <div
                onClick={ShowEquipmentModal}
                className={
                  DownEquipment()
                    ? "modalButton flexRow pointer downEquipment"
                    : "modalButton flexRow pointer"
                }
              >
                Assign Equipment
              </div>
            </div> */}
          </div>
          <div className="basicJobInfoCircleColumn flexRow spaceAround">
            <ProgressGraphic
              label="Square Foot Completed"
              value={completedSquareFoot}
              base={basicInfo.totalSquareFoot}
            />
            <ProgressGraphic
              label="Total Time"
              value={basicInfo.actualTime}
              base={basicInfo.estimatedTime}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default BasicJobInfo;
