import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import EditableValue from "../EditableValue/EditableValue";
import axios from "axios";
import * as Constants from "../constants";
// import axios from "axios";
// import * as Constants from "../constants";
// import { useMsal } from "@azure/msal-react";

function Employee(props) {
  const [currentPermission, SetCurrentPermission] = useState(
    props.employee.highestPermission
  );
  const [editable, SetEditable] = useState(false);
  console.log(props.user.employeeInfo);
  const uid = useMsal().accounts[0].username;

  function AllowEdit() {
    SetEditable(true);
  }

  function ChangePermission(e) {
    SetCurrentPermission(e.target.value);
  }

  function UpdatePermission() {
    var formData = new FormData();
    formData.append("uid", uid);
    formData.append("employeeId", props.employee.id);
    formData.append("permissionId", currentPermission);
    axios({
      method: "put",
      url:
        Constants.jobApiUrl +
        "UpdateEmployeePermissions?code=" +
        Constants.APIKey,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    }).then((res) => {
      props.RefreshEmployees();
      SetEditable(false);
    });
  }

  function CancelClicked() {
    SetCurrentPermission(props.employee.highestPermission);
    SetEditable(false);
  }

  function FilteredPermissions() {
    if (props.user.employeeInfo.highestPermission === 6) {
      return props.permissions;
    }
    if (props.user.employeeInfo.highestPermission === 2) {
      return props.permissions.filter((p) => p.id !== 6);
    }
    return props.permissions.filter(
      (p) => p.id !== 6 && p.id !== 2 && p.id !== 3
    );
  }

  function CheckEditAvailableForCurrentPermission() {
    if (props.user.employeeInfo.highestPermission === 6) {
      return true;
    }
    if (
      props.user.employeeInfo.highestPermission === 2 &&
      currentPermission !== 6
    ) {
      return true;
    }
    if (
      props.user.employeeInfo.highestPermission === 3 &&
      currentPermission !== 6 &&
      currentPermission !== 2 &&
      currentPermission !== 3
    ) {
      return true;
    }
    return false;
  }

  function GetSkills() {
    if (props.employee.skills.length === 0) {
      return "";
    }
    const skillsStringArray = [];
    props.employee.skills.forEach((skill) => {
      skillsStringArray.push(skill.name);
    });
    return skillsStringArray.join(" | ");
  }

  return (
    <div className="employeeListing">
      <h3>{props.employee.name}</h3>
      <div className="flexRow">{GetSkills()}</div>
      <div>
        {props.permissions != null ? (
          <EditableValue
            value={
              currentPermission != 0
                ? props.permissions.find((p) => p.id == currentPermission).name
                : null
            }
            editable={editable}
            selectValues={FilteredPermissions()}
            onChange={ChangePermission}
          />
        ) : (
          <div></div>
        )}
      </div>
      <div className="flexRow rightAlignContent">
        {CheckEditAvailableForCurrentPermission() ? (
          <>
            {editable ? (
              <div
                className="modalButton2 flexRow pointer"
                onClick={UpdatePermission}
              >
                Submit
              </div>
            ) : (
              <div className="modalButton2 flexRow pointer" onClick={AllowEdit}>
                Edit
              </div>
            )}
            {editable ? (
              <div
                className="modalButton2 flexRow pointer"
                onClick={CancelClicked}
              >
                Cancel
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Employee;
