import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as Constants from "../../../constants";
import { useMsal } from "@azure/msal-react";
import JobFilesItem from "./JobFilesItem";

const JobFilesModal = (props) => {
  const [jobFiles, SetJobFiles] = useState();

  const uid = useMsal().accounts[0].username;

  const [file, SetFile] = useState();

  function GetJobFiles() {
    axios
      .get(Constants.jobApiUrl + "GetFilesForJob", {
        params: {
          code: Constants.APIKey,
          jobId: props.attachedId,
        },
      })
      .then((res) => {
        SetJobFiles(res.data);
      });
  }

  function ChangeFile(e) {
    console.log(e.target.files[0]);
    SetFile(e.target.files[0]);
  }

  function UpLoadFiles() {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("jobId", props.attachedId);
    axios({
      method: "post",
      url: Constants.jobApiUrl + "UploadFile?code=" + Constants.APIKey,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then((response) => {
        console.log(response);
        GetJobFiles();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    GetJobFiles();
  }, []);

  return (
    <div className="JobFilesModal">
      <h2>Job Files</h2>
      <div className="JobFilesActions">
        <input type="file" id="file" onChange={ChangeFile} />
        <button onClick={UpLoadFiles}>Upload</button>
      </div>
      <div className="JobFilesItemSection">
        <div className="JobFilesItem">
          {jobFiles != null ? (
            jobFiles.map((f) => <JobFilesItem key={f.URI} file={f} />)
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobFilesModal;
