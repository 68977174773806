import React, { useState } from "react";
import axios from "axios";
import * as Constants from "../../../constants";
import EditableValue from "../../../EditableValue/EditableValue";
import { useMsal } from "@azure/msal-react";

function AddJob(props) {
  const [jobName, SetJobName] = useState("");
  const [startDate, SetStartDate] = useState("");
  const [endDate, SetEndDate] = useState("");
  const [squareFoot, SetSquareFoot] = useState("");
  const [projectManager, SetProjectManager] = useState();
  const [projectContact, SetProjectContact] = useState();
  const [status, SetStatus] = useState();
  const [message, SetMessage] = useState();

  const editable = true;

  const uid = useMsal().accounts[0].username;

  function Add() {
    if (
      jobName === "" ||
      startDate === "" ||
      endDate === "" ||
      squareFoot === "" ||
      projectManager === "" ||
      projectManager == null ||
      projectContact === "" ||
      projectContact == null ||
      status === "" ||
      status == null
    ) {
      SetMessage("All fields are required.");
      return;
    }

    var formData = new FormData();
    formData.append("uid", uid);
    formData.append("name", jobName);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("sqFoot", squareFoot);
    formData.append("projectManagerId", projectManager);
    formData.append("projectContactId", projectContact);
    formData.append("status", status);
    axios({
      method: "post",
      url: Constants.jobApiUrl + "AddJob?code=" + Constants.APIKey,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    }).then((res) => {
      props.RefreshList("Jobs");
      props.CloseModal();
    });
  }

  function ChangeJobName(e) {
    SetJobName(e.target.value);
  }

  function ChangeStartDate(e) {
    SetStartDate(e.target.value);
  }

  function ChangeEndDate(e) {
    SetEndDate(e.target.value);
  }

  function ChangeSquareFoot(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      SetSquareFoot(e.target.value);
    }
  }

  function ChangeProjectManager(e) {
    SetProjectManager(e.target.value);
  }

  function ChangeProjectContact(e) {
    SetProjectContact(e.target.value);
  }

  function ChangeStatus(e) {
    SetStatus(e.target.value);
  }

  return (
    <div className="addJobModal flexColumn">
      <h2>Add New Job</h2>
      <div className="AddJobInputs">
        <div className="AddJobInputsInner">
          <EditableValue
            label="Job Name:"
            value={jobName}
            editable={editable}
            onChange={ChangeJobName}
          />
          <EditableValue
            label="Start Date:"
            value={startDate}
            editable={editable}
            onChange={ChangeStartDate}
            isDate={true}
          />
          <EditableValue
            label="End Date:"
            value={endDate}
            editable={editable}
            onChange={ChangeEndDate}
            isDate={true}
          />
          <EditableValue
            label="Square Foot:"
            value={squareFoot}
            editable={editable}
            onChange={ChangeSquareFoot}
          />
          <EditableValue
            label="Project Manager:"
            value={projectManager}
            editable={editable}
            onChange={ChangeProjectManager}
            selectValues={props.dropDownData.employees}
          />
          <EditableValue
            label="Project Contact:"
            value={projectContact}
            editable={editable}
            onChange={ChangeProjectContact}
            selectValues={props.dropDownData.contacts}
          />
          <EditableValue
            label="Status:"
            value={status}
            editable={editable}
            onChange={ChangeStatus}
            selectValues={props.dropDownData.statuses}
          />
        </div>
      </div>
      <div className="errorMessage">{message}</div>
      <div className="modalButton flexRow pointer" onClick={Add}>
        Submit
      </div>
    </div>
  );
}

export default AddJob;
