import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Scheduler from "../Scheduler/Scheduler";
import TimeManagement from "../Time/TimeManagement";
import Modal from "./Modal";
import EmployeeManagement from "../EmployeeManagement/EmployeeManagement";
import EquipmentManagement from "../EquipmentManagement/EquipmentManagement";
import axios from "axios";
import * as Constants from "../constants";

function Base(props) {
  const [showModal, SetShowModal] = React.useState(false);
  const [showModal2, SetShowModal2] = React.useState(false);
  const [modalContent, SetModalContent] = React.useState(<></>);
  const [modalContent2, SetModalContent2] = React.useState(<></>);
  const [dropDownData, SetDropDownData] = React.useState(null);

  function ShowModal(content) {
    SetModalContent(content);
    SetShowModal(true);
  }

  function ShowModal2(content) {
    if (content === null) {
      CloseModal2();
    } else {
      SetModalContent2(content);
      SetShowModal2(true);
    }
  }

  function CloseModal() {
    SetShowModal(false);
    SetModalContent(<></>);
  }

  function CloseModal2() {
    SetShowModal2(false);
    SetModalContent2(<></>);
  }

  function GetDropDownData() {
    axios
      .get(Constants.jobApiUrl + "GetDropDownData", {
        params: {
          code: Constants.APIKey,
        },
      })
      .then((res) => {
        SetDropDownData(res.data);
      });
  }

  useEffect(() => {
    GetDropDownData();
  }, []);

  return (
    <>
      <div className={showModal ? "base disabled" : "base"}>
        <div className={showModal ? "dim" : "notDim"}>
          <BrowserRouter>
            <Header
              ShowModal={ShowModal}
              ShowModal2={ShowModal2}
              modal2State={showModal2}
              user={props.user}
              RefreshUserInfo={props.RefreshUserInfo}
              dropDownData={dropDownData}
            />
            <div className="mainViewport">
              <div className="mainViewportFrame flexColumn">
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Scheduler
                        ShowModal={ShowModal}
                        CloseModal={CloseModal}
                        modalState={showModal}
                        ShowModal2={ShowModal2}
                        modal2State={showModal2}
                        dropDownData={dropDownData}
                        user={props.user}
                      />
                    }
                  />
                  <Route
                    path="/Employees"
                    element={<EmployeeManagement user={props.user} />}
                  />
                  <Route
                    path="/Equipment"
                    element={<EquipmentManagement user={props.user} />}
                  />
                  <Route
                    path="/Time"
                    element={
                      <TimeManagement
                        user={props.user}
                        ShowModal={ShowModal}
                        CloseModal={CloseModal}
                        modalState={showModal}
                        RefreshUserInfo={props.RefreshUserInfo}
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
            <Footer />
          </BrowserRouter>
        </div>
      </div>
      <div className={showModal2 ? "disabled" : ""}>
        <div className={showModal2 ? "dim" : "notDim"}>
          <Modal HandleClose={CloseModal} show={showModal}>
            {modalContent}
          </Modal>
        </div>
      </div>
      <Modal HandleClose={CloseModal2} show={showModal2}>
        {modalContent2}
      </Modal>
    </>
  );
}

export default Base;
