import React from "react";

function ProgressGraphic(props) {
  const currentPercentage =
    isNaN((props.value / props.base) * 100) || props.base === 0
      ? 0
      : (props.value / props.base) * 100;

  return (
    <>
      {props.value != null && props.base != null ? (
        <div className="progressGraphic flexColumn">
          <div className="progressGraphicLabel">{props.label}</div>
          <div
            className="progressGraphicMain"
            data-tip={props.value.toFixed(2) + " / " + props.base}
          >
            <div
              className={
                currentPercentage > 100 && props.base != 0
                  ? "progressGraphicTextOverloaded flexColumn fullHeight fullWidth"
                  : "progressGraphicText flexColumn fullHeight fullWidth"
              }
            >
              {currentPercentage.toFixed(0)}%
            </div>
            <div
              className="progressGraphicInner"
              style={{
                height:
                  currentPercentage > 100
                    ? 100 + "px"
                    : currentPercentage.toFixed(0) + "px",
              }}
            ></div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default ProgressGraphic;
