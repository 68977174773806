import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../../constants";
import { useMsal } from "@azure/msal-react";
import EquipmentItem from "./EquipmentItem";

const EquipmentModal = (props) => {
  const [assignedEquipment, SetAssignedEquipment] = useState(
    props.assignedList
  );
  const [unassignedEquipment, SetUnassignedEquipment] = useState([]);

  const uid = useMsal().accounts[0].username;

  function UpdateEquipment() {
    var assignedIds = "";
    for (var i = 0; i < assignedEquipment.length; i++) {
      assignedIds += assignedEquipment[i].id;
      if (i + 1 < assignedEquipment.length) {
        assignedIds += ",";
      }
    }

    var formData = new FormData();
    formData.append("uid", uid);
    formData.append("mainId", props.attachedId);
    formData.append("assignedIds", assignedIds);
    formData.append("type", props.type);
    axios({
      method: "put",
      url: Constants.jobApiUrl + "AssignItems?code=" + Constants.APIKey,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formData,
    }).then((res) => {
      props.RefreshJobDetail();
      props.CloseSubModal();
    });
  }

  function AddEquipment(id) {
    var referencedEquipment = props.allEquipment.find((e) => e.id === id);
    var newAssignedEquipmentList = assignedEquipment.slice();
    newAssignedEquipmentList.push(referencedEquipment);
    SetAssignedEquipment(
      newAssignedEquipmentList.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
    console.log("added " + newAssignedEquipmentList.length);
  }

  function RemoveEquipment(id) {
    var newAssignedEquipmentList = assignedEquipment.filter((e) => e.id !== id);
    SetAssignedEquipment(
      newAssignedEquipmentList.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
    console.log("remove " + newAssignedEquipmentList.length);
  }

  function SubmitClicked() {
    UpdateEquipment();
  }

  function GenerateUnassignedEquipment() {
    var newUnassignedEquipmentList = props.allEquipment;
    assignedEquipment.forEach(function (equipment) {
      newUnassignedEquipmentList = newUnassignedEquipmentList.filter(
        (e) => e.id !== equipment.id
      );
    });
    console.log("triggered");
    SetUnassignedEquipment(
      newUnassignedEquipmentList.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    );
  }

  useEffect(() => {
    GenerateUnassignedEquipment();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedEquipment]);

  return (
    <div className="EquipmentModal">
      <h2>Assign Equipment</h2>
      <div className="multiAddListSection flexRow">
        <div className="multiAddListColumn">
          Assigned
          <div className="multiAddList">
            {assignedEquipment[0] != null ? (
              assignedEquipment.map((e) => (
                <EquipmentItem
                  key={e.id}
                  equipment={e}
                  ClickAction={RemoveEquipment}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="multiAddListColumn">
          Available
          <div className="multiAddList">
            {unassignedEquipment[0] != null ? (
              unassignedEquipment.map((e) => (
                <EquipmentItem
                  key={e.id}
                  equipment={e}
                  ClickAction={AddEquipment}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="submitButton pointer" onClick={SubmitClicked}>
        Submit
      </div>
    </div>
  );
};

export default EquipmentModal;
