import React from "react";
import { useSearchParams } from "react-router-dom";

function StatusFilter(props) {
  const [params, SetParams] = useSearchParams();
  const URLParams = Object.fromEntries([...params]);

  function HandleChange(checked, name) {
    let statusList =
      URLParams.status == null
        ? []
        : URLParams.status.split(",").filter((s) => s !== "");
    if (checked) {
      statusList.push(name);
      SetParams({ ...URLParams, status: statusList.join(",") });
    } else {
      statusList = statusList.filter((s) => s != name);
      SetParams({ ...URLParams, status: statusList.join(",") });
    }
  }

  return (
    <div className="filterField statusFilter">
      <div className="statusFilterLabel">Status</div>
      <div className="flexRow">
        {props.dropDownData != null &&
          props.dropDownData.statuses.map((status) => (
            <div
              key={status.id}
              className="filterByItemScheduler flexRow leftAlignContent"
            >
              <input
                type="checkbox"
                className="filterCheckbox"
                onChange={(e) => HandleChange(e.target.checked, status.name)}
              />
              {status.name}
            </div>
          ))}
      </div>
    </div>
  );
}

export default StatusFilter;
