import React from "react";
import { useSearchParams } from "react-router-dom";

function DatesHeader(props) {
  const [params] = useSearchParams();
  const URLParams = Object.fromEntries([...params]);

  function TimeLineDays() {
    let days = [];
    var dayToRepresent = new Date(URLParams.startDate);
    for (let i = 0; i < props.numberOfDays; i++) {
      let month = dayToRepresent.getUTCMonth() + 1;
      dayToRepresent.setDate(dayToRepresent.getDate() + 1);
      days.push(
        <div
          key={i}
          className="ganttHeaderDay flexRow"
          style={{
            width: props.gridSize + "px",
            fontSize:
              props.gridSize < 50 ? props.gridSize * 0.33 + "px" : "18px",
          }}
        >
          {month + "/" + dayToRepresent.getDate()}
        </div>
      );
    }
    return days;
  }

  return <div className="flexRow">{TimeLineDays()}</div>;
}

export default DatesHeader;
