export const jobApiUrl = "https://skida-fnc-jobs.azurewebsites.net/api/";
// export const jobApiUrl = "http://localhost:7071/api/";
export const APIKey =
  "ZTOAynDfz3FOJqnBwnIMvQLMT0HKkoKzeJbU-g_q5jBWAzFuvbCq3A==";
export const AllowedTenants = [
  "58b25321-3045-4059-94ca-94f05e33e2f1",
  "d5dcdeda-c530-4934-91a4-2b3446bf98e5",
  "4a1506fb-fcc2-4592-baa9-8d85aeb5216c",
];
export const redirectUri = window.location.href.includes("localhost")
  ? "http://localhost:3000"
  : window.location.href.includes("skidabrader")
  ? "https://app.skidabrader.com"
  : "https://skida-web-dev.azurewebsites.net";
export const ShowLoading = () => {
  document.getElementById("loadingScreen").classList.remove("displayNone");
};
export const HideLoading = () => {
  document.getElementById("loadingScreen").classList.add("displayNone");
};
export const FormatDate = (date) => {
  if (date.includes("-")) {
    return date;
  }
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
