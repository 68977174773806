import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Constants from "../constants";

function TimePunchItem(props) {
  const [selectedJob, SetSelectedJob] = useState([]);
  const [selectedStation, SetSelectedStation] = useState([]);
  const [selectedCostCode, SetSelectedCostCode] = useState([]);
  const [comments, SetComments] = useState("");
  const [responseComments, SetResponseComments] = useState("");
  const [timeIn, SetTimeIn] = useState();
  const [timeOut, SetTimeOut] = useState();
  const [status, SetStatus] = useState();
  const [statusName, SetStatusName] = useState(null);

  function SetTimepunchInformation() {
    if (props.timepunch.jobId != 0) {
      let job = props.user.assignedJobs.find(
        (j) => j.id === props.timepunch.jobId
      );
      SetSelectedJob(job);
      let station = job.stations.find(
        (s) => s.id === props.timepunch.stationId
      );
      SetSelectedStation(station);
    }
    let costCode = props.user.costCodes.find(
      (c) => c.id === props.timepunch.costCode
    );
    SetSelectedCostCode(costCode);
    SetComments(props.timepunch.comments);
    SetTimeIn(new Date(props.timepunch.timeIn));
    SetTimeOut(new Date(props.timepunch.timeOut));
  }

  function UpdateTimePunch() {
    var formData = new FormData();
    formData.append("uid", props.user.employeeInfo.email);
    formData.append("id", props.timepunch.id);
    formData.append("jobId", selectedJob.id);
    formData.append("stationId", selectedStation.id);
    formData.append("employeeId", props.employee.id);
    formData.append("timeIn", timeIn.toLocaleTimeString());
    formData.append("timeOut", timeOut.toLocaleTimeString());
    formData.append("costCode", selectedCostCode.id);
    formData.append("latitude", props.timepunch.latitude);
    formData.append("longitude", props.timepunch.longitude);
    formData.append("status", status);
    formData.append("comments", props.timepunch.comments);
    formData.append("responseComments", responseComments);
    console.log(formData);
    axios({
      method: "put",
      url: Constants.jobApiUrl + "UpdateTimePunch?code=" + Constants.APIKey,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then(() => {
        props.RemoveTimePunch(props.timepunch.id);
        props.RefreshUserInfo();
        console.log("success");
      })
      .catch(function (error) {
        console.log(error.message);
      });
  }

  function SetApprovalStatus() {
    SetStatus(4);
  }

  function SetDenialStatus() {
    if (responseComments != null && responseComments != "") {
      SetStatus(5);
    } else {
      alert("Please enter a response comment");
    }
  }

  useEffect(() => {
    SetTimepunchInformation();
  }, [props.timepunch]);

  useEffect(() => {
    if (status != null) {
      UpdateTimePunch();
    }
  }, [status]);

  return (
    <div className="timePunchItem">
      <div className="timePunchItemHeader">{selectedJob.name}</div>
      <div className="timePunchItemBody">
        <div className="timePunchItemBodyLeft">
          <div className="timePunchItemBodyLeftItem">
            <div className="timePunchItemBodyLeftItemContainer">
              <div className="timePunchItemBodyLeftItemLabel">Station</div>
              <div className="timePunchItemBodyLeftItemValue">
                {selectedStation.name}
              </div>
            </div>
            <div className="timePunchItemBodyLeftItemContainer">
              <div className="timePunchItemBodyLeftItemLabel">Cost Code</div>
              <div className="timePunchItemBodyLeftItemValue">
                {selectedCostCode.name}
              </div>
            </div>
            <div className="timePunchItemBodyLeftItemContainer">
              <div className="timePunchItemBodyLeftItemLabel">Time In</div>
              <div className="timePunchItemBodyLeftItemValue">
                {new Date(props.timepunch.timeIn).toLocaleTimeString()}
              </div>
            </div>
            <div className="timePunchItemBodyLeftItemContainer">
              <div className="timePunchItemBodyLeftItemLabel">Time Out</div>
              <div className="timePunchItemBodyLeftItemValue">
                {new Date(props.timepunch.timeOut).toLocaleTimeString()}
              </div>
            </div>
            <div className="timePunchItemBodyLeftItemContainer">
              <div className="timePunchItemBodyLeftItemLabel">Comments</div>
              <div className="timePunchItemBodyLeftItemValue">
                {props.timepunch.comments}
              </div>
            </div>
          </div>
          <div className="timePunchItemBodyLeftItemContainer response">
            <div className="timePunchItemBodyLeftItemLabel">
              Response Comments
            </div>
            <div className="timePunchItemBodyLeftItemValue">
              <input
                type="text"
                onChange={(e) => SetResponseComments(e.target.value)}
              />
            </div>
          </div>
          <div className="timePunchItemBodyLeftItemButtonContainer">
            <div className="flexRow">
              <div
                className="modalButton flexRow pointer"
                onClick={SetApprovalStatus}
              >
                Approve
              </div>
            </div>
            <div className="flexRow">
              <div
                className="modalButton flexRow pointer"
                onClick={SetDenialStatus}
              >
                Return
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimePunchItem;
