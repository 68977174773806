import React from "react";
import DailyReportModal from "../../DailyReports/DailyReportModal";

function DailyReportItem(props) {
  function ShowReportModal() {
    props.ShowModal2(<DailyReportModal report={props.report} />);
  }

  return (
    <>
      <div
        className="dailyReportListing flexRow pointer"
        key={props.report.id}
        onClick={ShowReportModal}
      >
        <div>{props.report.reportDate}</div>
        <div>{props.report.supervisor}</div>
      </div>
    </>
  );
}

export default DailyReportItem;
