import React, { useEffect, useState } from "react";
import BasicJobInfo from "./BasicJobInfo/BasicJobInfo";
import JobStations from "./JobStations/JobStations";
import DetailedInfo from "./DetailedInfo/DetailedInfo";
import axios from "axios";
import * as Constants from "../../../constants";
import EditableValue from "../../../EditableValue/EditableValue";

const JobInfoModal = (props) => {
  const [currentJobDetail, SetCurrentJobDetail] = useState();
  const [editable, SetEditable] = useState(false);
  const [newName, SetNewName] = useState();
  const [equipmentList, SetEquipmentList] = useState();
  const [employeeList, SetEmployeeList] = useState();
  const [skillList, SetSkillList] = useState();
  const [dailyReports, SetDailyReports] = useState();
  // eslint-disable-next-line
  const [jobFiles, SetJobFiles] = useState();

  function GetJobDetail() {
    axios
      .get(Constants.jobApiUrl + "GetJobDetail", {
        params: {
          code: Constants.APIKey,
          jobId: props.job.id,
        },
      })
      .then((res) => {
        SetCurrentJobDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
        alert(
          "Error getting job detail, if you were updating a job, it may have processed. Please refresh the page to verify."
        );
      });
  }

  function GetEquipment() {
    axios
      .get(Constants.jobApiUrl + "GetEquipment", {
        params: {
          code: Constants.APIKey,
        },
      })
      .then((res) => {
        SetEquipmentList(res.data);
      });
  }

  function GetEmployees() {
    axios
      .get(Constants.jobApiUrl + "GetEmployees", {
        params: {
          code: Constants.APIKey,
        },
      })
      .then((res) => {
        SetEmployeeList(res.data);
      });
  }

  function GetSkills() {
    axios
      .get(Constants.jobApiUrl + "GetSkills", {
        params: {
          code: Constants.APIKey,
        },
      })
      .then((res) => {
        SetSkillList(res.data);
      });
  }

  function GetDailyReports() {
    axios
      .get(Constants.jobApiUrl + "GetDailyReport", {
        params: {
          code: Constants.APIKey,
          jobId: props.job.id,
        },
      })
      .then((res) => {
        SetDailyReports(res.data);
      });
  }

  function InitializeName() {
    SetNewName(props.job.name);
  }

  function ChangeName(event) {
    SetNewName(event.target.value);
  }

  function ChangeEditable(value) {
    SetEditable(value);
  }

  useEffect(() => {
    GetJobDetail();
    GetEquipment();
    GetEmployees();
    GetSkills();
    GetDailyReports();
    InitializeName();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.job]);

  return (
    <div className="jobInfoModal">
      <div className="jobInfoModalHeader flexRow spaceBetween fullWidth">
        <h2 className="textLeft flexRow">
          {props.job.entityId}
          <EditableValue
            value={newName}
            editable={editable}
            onChange={ChangeName}
            inputWidth="400px"
            type="jobHeader"
          />
        </h2>
      </div>

      <div className="jobInfoModalContent flexColumn">
        {currentJobDetail != null ? (
          <>
            <div className="basicJobInfoBlock flexColumn">
              <BasicJobInfo
                job={props.job}
                RefreshJobDetail={GetJobDetail}
                jobDetail={currentJobDetail}
                ShowModal2={props.ShowModal2}
                modal2State={props.modal2State}
                equipmentList={equipmentList}
                employeeList={employeeList}
                skillList={skillList}
                dropDownData={props.dropDownData}
                newName={newName}
                ChangeEditable={ChangeEditable}
                jobFiles={jobFiles}
                user={props.user}
              />
            </div>
            <div className="jobStationsBlock flexColumn">
              <div className="jobStationsHeader" />
              <div className="jobStationsBody flexColumn">
                <JobStations
                  job={props.job}
                  jobDetail={currentJobDetail}
                  RefreshDetail={GetJobDetail}
                  ShowModal2={props.ShowModal2}
                  modal2State={props.modal2State}
                  equipmentList={equipmentList}
                  employeeList={employeeList}
                  dropDownData={props.dropDownData}
                  skillList={skillList}
                  user={props.user}
                  selectedStation={props.selectedStation}
                />
              </div>
            </div>
            <div className="detailedJobInfoBlock flexColumn">
              <DetailedInfo
                job={props.job}
                jobDetail={currentJobDetail}
                dailyReports={dailyReports}
                ShowModal2={props.ShowModal2}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default JobInfoModal;
