import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../../../constants";
import { useMsal } from "@azure/msal-react";

const ManualSquareFootAddModal = (props) => {
  const [squareFeet, SetSquareFeet] = useState("");
  const [date, SetDate] = useState(new Date().toISOString().slice(0, 10));
  const [memo, SetMemo] = useState("");
  const [equipmentList, SetEquipmentList] = useState(props.station.equipment);
  const [equipment, SetEquipment] = useState("");
  const [equipmentTime, SetEquipmentTime] = useState("");

  const uid = useMsal().accounts[0].username;

  function validateData() {
    if (squareFeet === "") {
      alert("Please enter square feet.");
      return false;
    }
    if (equipment === "") {
      alert("Please select equipment.");
      return false;
    }
    if (equipmentTime === "") {
      alert("Please enter equipment time.");
      return false;
    }
    return true;
  }

  function AddSquareFeet() {
    Constants.ShowLoading();
    var formData = new FormData();
    formData.append("uid", props.user.employeeInfo.email);
    formData.append("employeeId", props.user.employeeInfo.id);
    formData.append("stationId", props.station.id);
    formData.append("newSquareFoot", squareFeet);
    formData.append("date", FormatDate(date));
    formData.append("memo", memo);
    formData.append("equipmentId", equipment);
    formData.append("newMachineHours", equipmentTime);
    axios({
      method: "put",
      url: Constants.jobApiUrl + "UpdateSquareFoot?code=" + Constants.APIKey,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then(function (response) {
        Constants.HideLoading();
        props.RefreshJobDetail();
        props.CloseSubModal();
        switch (response.data) {
          case "Error Sync":
            alert("Failed to sync");
            break;
          case "Error Machine Hours":
            alert("Failed to update machine hours");
            break;
          case "Error Square Foot":
            alert("Failed to update square foot");
            break;
          case "Error Full":
            alert("Failed to update");
            break;
          case "success":
            alert("Station updated successfully.");
            break;
        }
      })
      .catch(function (error) {
        Constants.HideLoading();
        console.log(error.message);
        alert("Failed to update station - " + error.message);
        props.RefreshJobDetail();
        props.CloseSubModal();
      });
  }

  function ChangeEquipment(event) {
    SetEquipment(event.target.value);
  }

  function ChangeEquipmentTime(event) {
    const regex = /^[0-9.\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      SetEquipmentTime(event.target.value);
    }
  }

  function ChangeSquareFeet(event) {
    const regex = /^[0-9.\b]+$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
      SetSquareFeet(event.target.value);
    }
  }

  function FormatDate(date) {
    if (date.includes("-")) {
      return date;
    }
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function SubmitClicked() {
    if (validateData()) {
      let confirmed = window.confirm(
        "Once submitted, this Sq. ft can not be removed. Are you sure you want to add this time?"
      );
      if (confirmed) {
        AddSquareFeet();
      }
    }
  }

  function ChangeDate(event) {
    SetDate(event.target.value);
  }

  function ChangeMemo(event) {
    SetMemo(event.target.value);
  }

  function GenerateLabel(label, label2, superscript) {
    return (
      <div className="manualSquareFootEntryCell colonAlign boldText right">
        {label}
        <sup>{superscript}</sup>
        {label2}
      </div>
    );
  }

  function GenerateHeader(label, label2, superscript) {
    return (
      <h2>
        {label}
        <sup>{superscript}</sup>
        {label2}
      </h2>
    );
  }

  return (
    <div className="manualSquareFootAddModal">
      {/* <h2>Add Sq. ft Manually</h2> */}
      {GenerateHeader("Add Ft", " And Wheel Hours", "2")}
      <div className="manualSquareFootMainBody">
        <div className="manualSquareFootEntryCell">
          <input
            className="manualSquareFootEntrySubmit"
            type="date"
            value={FormatDate(date)}
            onChange={ChangeDate}
          />
        </div>
        <div className="manualSquareFootEntry">
          {GenerateLabel("Current Completed Ft", " :", "2")}
          <div className="manualSquareFootEntryCell left">
            {props.station.squareFeetCompleted}
          </div>
          {GenerateLabel("Ft", " To Add :", "2")}
          <div className="manualSquareFootEntryCell left">
            <input
              className="manualSquareFootEntryInput"
              type="text"
              placeholder="0"
              value={squareFeet}
              onChange={ChangeSquareFeet}
            />
          </div>
          <div className="manualSquareFootEntryCell colonAlign boldText right">
            {" "}
            Equipment :
          </div>
          <div className="manualSquareFootEntryCell left">
            <select
              className="manualSquareFootEntryInput select"
              value={equipment}
              onChange={ChangeEquipment}
            >
              <option value="">Select</option>
              {equipmentList.map((equipment) => (
                <option value={equipment.id}>{equipment.name}</option>
              ))}
            </select>
          </div>
          <div className="manualSquareFootEntryCell colonAlign boldText right">
            {" "}
            Wheel Hours :
          </div>
          <div className="manualSquareFootEntryCell left">
            <input
              className="manualSquareFootEntryInput"
              type="text"
              placeholder="0"
              value={equipmentTime}
              onChange={ChangeEquipmentTime}
            />
          </div>
        </div>
        <div className="manualSquareFootCell">
          <textarea
            className="manualSquareFootEntryTextArea"
            value={memo}
            onChange={ChangeMemo}
            placeholder="Memo"
          />
        </div>
        <div
          className="submitButton pointer centerButton"
          onClick={SubmitClicked}
        >
          Submit
        </div>
      </div>
    </div>
  );
};

export default ManualSquareFootAddModal;
