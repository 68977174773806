import React from "react";
import TimePunchItem from "./TimePunchItem";
import { useEffect, useState } from "react";

function EmployeeTimePunches(props) {
  const [employee, setEmployee] = useState();

  function RemoveTimePunch(id) {
    employee.timepunchesNeedingApproval =
      employee.timepunchesNeedingApproval.filter((tp) => tp.id != id);
  }

  useEffect(() => {
    if (props.employee != null) {
      setEmployee(props.employee);
    }
  }, [props.employee]);

  return (
    <div className="EmployeeTimePunchesModal">
      <h2>Employee Time Punches</h2>
      <div className="employeeTimePunchListing flexRow">
        {employee != null ? (
          employee.timepunchesNeedingApproval.map((t) => (
            <TimePunchItem
              key={t.id}
              timepunch={t}
              user={props.user}
              employee={employee}
              CloseModal={props.CloseModal}
              RefreshUserInfo={props.RefreshUserInfo}
              RemoveTimePunch={RemoveTimePunch}
            />
          ))
        ) : (
          <>Nothing</>
        )}
      </div>
    </div>
  );
}

export default EmployeeTimePunches;
